/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchAddSchedules = /* GraphQL */ `
  mutation BatchAddSchedules($batchAddScheduleInput: BatchAddScheduleInput) {
    batchAddSchedules(batchAddScheduleInput: $batchAddScheduleInput) {
      shopId
      data {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPreReservation = /* GraphQL */ `
  mutation CreatePreReservation(
    $preReservationInput: CreatePreReservationInput
    $customerInput: CustomerInput
  ) {
    createPreReservation(
      preReservationInput: $preReservationInput
      customerInput: $customerInput
    ) {
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      address
      email
      tel
      dayoff
      holidays
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      address
      email
      tel
      dayoff
      holidays
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      address
      email
      tel
      dayoff
      holidays
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      shopId
      name
      thumbnail
      description
      duration
      capacity
      canPublish
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      shopId
      name
      thumbnail
      description
      duration
      capacity
      canPublish
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      shopId
      name
      thumbnail
      description
      duration
      capacity
      canPublish
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      shopId
      id
      eventId
      event {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      capacity
      acceptableNumber
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      isExpired
      createdAt
      updatedAt
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      shopId
      id
      eventId
      event {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      capacity
      acceptableNumber
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      isExpired
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      shopId
      id
      eventId
      event {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      capacity
      acceptableNumber
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      isExpired
      createdAt
      updatedAt
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReservationTransaction = /* GraphQL */ `
  mutation CreateReservationTransaction(
    $input: CreateReservationTransactionInput!
    $condition: ModelReservationTransactionConditionInput
  ) {
    createReservationTransaction(input: $input, condition: $condition) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReservationTransaction = /* GraphQL */ `
  mutation UpdateReservationTransaction(
    $input: UpdateReservationTransactionInput!
    $condition: ModelReservationTransactionConditionInput
  ) {
    updateReservationTransaction(input: $input, condition: $condition) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReservationTransaction = /* GraphQL */ `
  mutation DeleteReservationTransaction(
    $input: DeleteReservationTransactionInput!
    $condition: ModelReservationTransactionConditionInput
  ) {
    deleteReservationTransaction(input: $input, condition: $condition) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
