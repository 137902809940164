import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { Navigate, Outlet, RouteProps } from "react-router-dom";

export const PublicRoute: React.FC<RouteProps> = () => {
  const [redirectPath, setPath] = useState<string>("/");
  const { user, authStatus } = useAuthenticator((context) => [
    context.route,
    context.authStatus
  ]);
  useEffect(() => {
    if (authStatus === "authenticated") {
      setPath(`/u/${user?.attributes?.sub!}/dashboard`);
    }
  }, [authStatus, user]);
  return authStatus === "authenticated" ? (
    <Navigate to={redirectPath} />
  ) : (
    <Outlet />
  );
};
