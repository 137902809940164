import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import React from "react";

type SelectItem = {
  key: string;
  label: string;
  value: string;
};

type Props = {
  label: string;
  items: SelectItem[];
  errorMessage?: string;
} & SelectProps;

export const SelectBox = React.forwardRef<HTMLInputElement, Props>(
  ({ items, label, errorMessage, ...props }, ref) => {
    return (
      <FormControl fullWidth>
        <InputLabel id={props.labelId}>{label}</InputLabel>
        <Select label={label} ref={ref} {...props}>
          {items.map((item) => (
            <MenuItem key={item.key} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {errorMessage ? <Typography color="error" variant="caption" mt={1}>{errorMessage}</Typography> : null}
      </FormControl>
    );
  }
);
