import {
  BatchAddScheduleInput,
  ScheduleInput,
  UpdateShopInput,
  UpdateEventInput,
  UpdateScheduleInput,
  DeleteEventInput,
  DeleteScheduleInput,
  UpdateCustomerInput,
} from "../API";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import {
  CreateEventItems,
  EventRepository,
  UpdateEventItems,
  DeleteEventItems,
} from "../DAL/repositories/EventRepository";
import {
  CancelReservationInput,
  PreReserveInput,
  ReservationRepository,
} from "../DAL/repositories/ReservationRepository";
import {
  ScheduleRepository,
  StyledCreateScheduleInput,
} from "../DAL/repositories/ScheduleRepository";
import { ShopRepository } from "../DAL/repositories/ShopRepository";
import { ResourceOperation } from "../util/graphqlEvents";
import { uploadImage2S3 } from "../util/storage";
import {
  StoreResource,
  StoreTransactionStatus,
} from "./ApplicationStoreProvider";
import {
  CreateEntityModel,
  DeleteEntityModel,
  UpdateEntityModel,
} from "./reducer/RepositoryAsyncReducer";
import { ACTIONTYPE } from "./reducer/StoreReducer";

export const EntityCURDServiceProvider = () => {
  const createEntity = async (
    dispatch: React.Dispatch<
      | ACTIONTYPE
      | {
          type: "CREATE_ENTITY_DATA";
          payload: CreateEntityModel;
        }
    >,
    data: CreateEntityModel
  ) => {
    switch (data.entityName) {
      case "Event":
        const _input = data.input as CreateEventItems;
        Promise.all([
          uploadImage2S3(
            _input.thumbnailName || null,
            _input.thumbnailData || null
          ),
          EventRepository(_input.shopId).create(_input.formState),
        ])
          .then((results) => {
            dispatch({
              type: ResourceOperation.CREATE_LESSON,
              payload: results[1],
            });
          })
          .catch((err) => {
            dispatch({
              type: ResourceOperation.SET_TRANSACTION,
              payload: {
                resource: StoreResource.event,
                method: "update",
                status: StoreTransactionStatus.error,
              },
            });
          });
        break;
      case "Schedule":
        const scheduleClient = ScheduleRepository(data.shopId!);
        let inputs = data.input as StyledCreateScheduleInput[];

        if (inputs.length === 1) {
          const {
            duration,
            eventName,
            ...scheduleInput
          } = inputs[0];
          scheduleClient
            .create(scheduleInput)
            .then((result) => {
              dispatch({
                type: ResourceOperation.CREATE_SCHEDULE,
                payload: result,
              });
            })
            .catch((err) => {
              dispatch({
                type: ResourceOperation.SET_TRANSACTION,
                payload: {
                  resource: StoreResource.schedule,
                  method: "update",
                  status: StoreTransactionStatus.error,
                },
              });
            });
        } else {
          const input: BatchAddScheduleInput = {
            shopId: inputs[0].shopId,
            data: inputs.map(
              ({duration,eventName, ...item }) =>
                item
            ) as [ScheduleInput],
          };
          scheduleClient
            .batchAdd(input)
            .then((results) => {
              dispatch({
                type: ResourceOperation.CREATE_SCHEDULES,
                payload: results,
              });
            })
            .catch((err) => {
              dispatch({
                type: ResourceOperation.SET_TRANSACTION,
                payload: {
                  resource: StoreResource.schedule,
                  method: "update",
                  status: StoreTransactionStatus.error,
                },
              });
            });
        }
        break;
      case "Reservation":
        const input = data.input as PreReserveInput;
        const reservationClient = ReservationRepository(data.shopId);
        const reservedScheduleClient = ScheduleRepository(data.shopId);
        reservationClient
          .preReserve(input)
          .then((_) =>
            reservedScheduleClient.get(
              input.createPreReservationInput.scheduleId
            )
          )
          .then((newSchedule) =>
            dispatch({
              type: ResourceOperation.UPDATE_SCHEDULE,
              payload: newSchedule,
            })
          );
        break;
      default:
        break;
    }
  };

  const updateEntity = async (
    dispatch: React.Dispatch<
      | ACTIONTYPE
      | {
          type: "UPDATE_ENTITY_DATA";
          payload: UpdateEntityModel;
        }
    >,
    data: UpdateEntityModel
  ) => {
    switch (data.entityName) {
      case "Shop":
        ShopRepository(data.shopId!)
          .update(data.input as UpdateShopInput)
          .then((result) => {
            dispatch({
              type: ResourceOperation.UPDATE_SHOP,
              payload: result,
            });
          });
        break;
      case "Event":
        const _input = data.input as UpdateEventItems;
        Promise.all([
          uploadImage2S3(
            _input.thumbnailName || null,
            _input.thumbnailData || null
          ),
          EventRepository(_input.shopId!).update(
            _input.formState as UpdateEventInput
          ),
        ])
          .then((results) => {
            dispatch({
              type: ResourceOperation.UPDATE_LESSON,
              payload: results[1],
            });
          })
          .catch((err) => {
            dispatch({
              type: ResourceOperation.SET_TRANSACTION,
              payload: {
                resource: StoreResource.event,
                method: "update",
                status: StoreTransactionStatus.error,
              },
            });
          });
        break;
      case "Schedule":
        ScheduleRepository(data.shopId!)
          .update(data.input as UpdateScheduleInput)
          .then((result) => {
            dispatch({
              type: ResourceOperation.UPDATE_SCHEDULE,
              payload: result,
            });
          });
        break;
      case "Reservation":
        const input = data.input as CancelReservationInput;
        ReservationRepository(data.shopId)
          .cancel(input)
          .then((result) => {
            dispatch({
              type: ResourceOperation.UPDATE_RESERVATION,
              payload: result,
            });
            return ScheduleRepository(data.shopId).get(input.updateInput.scheduleId!)
          }).then((newSchedule)=>{
            dispatch({
              type: ResourceOperation.GET_SCHEDULE,
              payload:newSchedule
            })
          });
        break;
      case "Customer":
        CustomerRepository(data.shopId!)
          .update(data.input as UpdateCustomerInput)
          .then((result) => {
            dispatch({
              type: ResourceOperation.UPDATE_CUSTOMER,
              payload: result,
            });
          });
        break;
      default:
        break;
    }
  };

  const deleteEntitiy = (
    dispatch: React.Dispatch<
      | ACTIONTYPE
      | {
          type: "DELETE_ENTITY_DATA";
          payload: DeleteEntityModel;
        }
    >,
    data: DeleteEntityModel
  ) => {
    switch (data.entityName) {
      case "Event":
        const _input = data.input as DeleteEventItems;
          EventRepository(data.shopId!)
            .remove({
              id: _input.target.id,
            } as DeleteEventInput)
            .then(() => {
              dispatch({
                type: ResourceOperation.DELETE_LESSON,
                payload: _input.target,
              });
            });
        
        break;
      case "Schedule":
        ScheduleRepository(data.shopId!)
          .remove(data.input as DeleteScheduleInput)
          .then((result) => {
            dispatch({
              type: ResourceOperation.DELETE_SCHEDULE,
              payload: result,
            });
          });
        break;
      default:
        break;
    }
  };

  return { createEntity, updateEntity, deleteEntitiy };
};
