import styled from "@emotion/styled";
import { Stack, StackProps } from "@mui/material";
import React from "react";

const StyledStack = styled(Stack)({
  marginLeft: "-1px",
  marginBottom: "1em",
  paddingLeft: "1em",
  borderLeftStyle: "solid",
  cursor: "pointer",
  boxSizing: "border-box",
});

type SideNaviStackProps = StackProps & {
  active?: boolean;
};

export const SideNaviStack = React.memo<SideNaviStackProps>(
  ({ active, children, ...props }) => {
    return (
      <StyledStack
        {...props}
        sx={{
          borderLeftWidth: active ? "3px" : "1px",
          borderLeftColor: active ? "primary.main" : "transparent",
          color: active ? "primary.main" : "inherit",
          ":hover": {
            borderLeftColor: "primary.main",
          },
        }}
      >
        {children}
      </StyledStack>
    );
  }
);
