import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageNavigationEvent, usePageNavigation } from "./../provider";
import { ItemProps } from "./../types";

export const HeaderNaviItem: React.FC<ItemProps> = ({
  navigationRoute,
  ...props
}) => {
  const { pageNavigationState, pageNavigationDispatcher } = usePageNavigation();
  const nagivate = useNavigate();
  return (
    <Chip
      label={navigationRoute.name}
      {...props}
      variant={
        pageNavigationState.currentRoute === navigationRoute.route ||
        pageNavigationState.parentRoute === navigationRoute.route
          ? "filled"
          : "unselected"
      }
      clickable={pageNavigationState.currentRoute !== navigationRoute.route}
      onClick={() => {
        pageNavigationDispatcher({
          type: PageNavigationEvent.onClickedHeaderNavi,
          payload: { currentRoute: navigationRoute.path },
        });
        nagivate(navigationRoute.path);
      }}
    />
  );
};
