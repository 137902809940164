import i18next from "i18next";
import { jaTranslation } from "./lang/ja";
import { enTranslation } from "./lang/en";

i18next.init({
  lng: "ja",
  debug: false,
  resources: {
    en: {
      translation: enTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
  },
});

export { i18next };
