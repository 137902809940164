import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import {
  BatchAddSchedulesMutation,
  CreateScheduleInput,
  CreateScheduleMutation,
  DeleteScheduleInput,
  ListSchedulesQuery,
  Schedule,
  UpdateScheduleInput,
  UpdateScheduleMutation,
  BatchAddScheduleInput,
  DeleteScheduleMutation,
  ModelEventFilterInput,
  GetScheduleQuery,
} from "../../API";
import {
  batchAddSchedules,
  createSchedule,
  deleteSchedule,
  updateSchedule,
} from "../../graphql/mutations";
import { _getSchedule, _listSchedules } from "../fixedQueries";

export type StyledCreateScheduleInput = CreateScheduleInput & {
  duration?: number
  capacity?: number
  acceptableNumber?: number,
  eventName?: string
}

export const ScheduleRepository = (shopId: string | undefined) => {
  const fetch = () => {
    const listFilter: ModelEventFilterInput = {
      isExpired: {
        eq: false,
      },
    } as ModelEventFilterInput;

    return new Promise<Schedule[]>(async (res, rej) => {
      if (shopId === undefined) rej();
      const result = (await API.graphql(
        graphqlOperation(_listSchedules, { shopId: shopId, filter: listFilter })
      )) as GraphQLResult<ListSchedulesQuery>;
 
      if (result.data?.listSchedules?.items) {
        const Schedules = result.data.listSchedules.items as Schedule[];
        res(Schedules);
      } else {
        rej(new Error("empty"));
      }
    });
  };

  const create = (formState: CreateScheduleInput) => {
    const newSchedule: CreateScheduleInput = { ...formState };
    return new Promise<Schedule>(async (res) => {
      const result = (await API.graphql(
        graphqlOperation(createSchedule, { input: newSchedule })
      )) as GraphQLResult<CreateScheduleMutation>;
      const Schedule = result.data?.createSchedule as Schedule;
      res(Schedule);
    });
  };

  const batchAdd = (input: BatchAddScheduleInput) => {
    return new Promise<[Schedule]>(async (res, rej) => {
      try {
        const result = (await API.graphql(
          graphqlOperation(batchAddSchedules, { batchAddScheduleInput: input })
        )) as GraphQLResult<BatchAddSchedulesMutation>;
        const schdules = result.data?.batchAddSchedules?.data as [Schedule];
        res(schdules);
      } catch (err) {
        rej(err);
      }
    });
  };

  const update = (input: UpdateScheduleInput) => {
    return new Promise<Schedule>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(updateSchedule, { input: input })
      )) as GraphQLResult<UpdateScheduleMutation>;
      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.updateSchedule as Schedule);
      }
    });
  };

  const remove = (input: DeleteScheduleInput) => {
    return new Promise<Schedule>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(deleteSchedule, { input: input })
      )) as GraphQLResult<DeleteScheduleMutation>;
      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.deleteSchedule as Schedule);
      }
    });
  };

  const get = (id: string) =>{
    return new Promise<Schedule>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(_getSchedule, {shopId: shopId, id: id})
      )) as GraphQLResult<GetScheduleQuery>;
      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.getSchedule as Schedule);
      }
    });
  }

  return {
    fetch,
    get,
    create,
    batchAdd,
    update,
    remove,
  };
};
