import { formatDate, formatRange } from "@fullcalendar/react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Customer,
} from "../../API";

import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import React from "react";
import { useNavigate } from "react-router-dom";

type CustomerRow = {
  id: string;
  name: string;
  email: string;
  lineId: string;
  createdAt: string;
};

type Props = {
  shopId: string,
  customers: Customer[];
};
export const CustomerDataGrid = React.memo<Props>(({ customers, shopId }) => {

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "氏名",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "作成日",
      type: "date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return formatDate(new Date(Date.parse(params.value)), { locale: "ja" });
      },
      disableColumnMenu: true,
    },
  ];

  const createGirdRows = (customers: Customer[]) => {
    return customers.map(
      (customer) =>
        ({
          id: customer.id,
          name: customer.name,
          email: customer.email!,
          lineId: customer.lineId,
          createdAt: customer.createdAt,
        } as CustomerRow)
    );
  };

  return (
    <DataGrid
      columns={columns}
      rows={createGirdRows(customers)}
      autoHeight
      density="compact"
      onRowClick={(e) => navigate(`/u/${shopId}/customer/${e.row.id}`)}
      sx={{
        "& .MuiDataGrid-cell": {
          ":focus": {
            outlineColor: "transparent",
          },
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
          ":hover": {
            color: "primary.main",
          },
        },
        "& .MuiDataGrid-columnHeader":{
          ":last-child":{
            ".MuiDataGrid-iconSeparator":{
              visibility:"hidden"
            }
          }
        }
      }}
    />
  );
});
