import { formatRange } from "@fullcalendar/react";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import React, { useCallback } from "react";
import { CreatePreReservationInput, CustomerInput, Reservation, Schedule } from "../../API";
import { PreReserveInput } from "../../DAL/repositories/ReservationRepository";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { CreateEntityModel } from "../../provider/reducer/RepositoryAsyncReducer";
import { CreatePreReservationDialog } from "../Dialogs/Reservation/CreatePreReservationDialog";
import { ScheduleReservedCustomerTable } from "../Table/ScheduleReservedCustomerTable";

type Props = {
  schedule: Schedule;
};

export const ReservationPerSchedulePanel = React.memo<Props>(({ schedule }) => {
  const { showModal } = useModal();
  const {store, dispatch} = useApplicationStore();
  const handleCreatePreReservationDialog = useCallback(() => {
    const modal = showModal(CreatePreReservationDialog, {
      title: "新規予約作成",
      description: "予約作成のための情報を入力してください。",
      schedule: schedule,
      onConfirm: (
        createPreReservationInput: CreatePreReservationInput,
        customerInput: CustomerInput
      ) => {
        console.log(createPreReservationInput)
        dispatch({
          type: "CREATE_ENTITY_DATA",
          payload: {
            shopId: store.shop?.id,
            entityName: "Reservation",
            input: {
              createPreReservationInput: createPreReservationInput,
              customerInput: customerInput
            } as PreReserveInput
          } as CreateEntityModel
        });
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  }, [schedule, showModal]);

  return (
    <Paper variant="outlined">
      <Box py={2.5} px={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">{schedule.event?.name}</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              size="small"
              label={`開催期間:${formatRange(schedule.start, schedule.end, {
                hour: "numeric",
                minute: "numeric",
                locale: "jp",
                separator: "-",
              })}`}
            />
            <Button
              variant="contained"
              size="small"
              onClick={handleCreatePreReservationDialog}
            >
              予約を追加する
            </Button>
          </Stack>
        </Stack>
        <Divider sx={{ mt: 1, mb: 1.5 }} />
        {schedule.reservations?.items.length! > 0 ? (
          <ScheduleReservedCustomerTable
            reservations={schedule.reservations?.items as Reservation[]}
          />
        ) : (
          <Card variant="outlined" elevation={0}>
            <Box p={1}>
              <Typography variant="body1">予約はありません</Typography>
            </Box>
          </Card>
        )}
      </Box>
    </Paper>
  );
});
