import { DateSelectArg, EventInput } from "@fullcalendar/react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from "@mui/material";

import { useCallback, useState } from "react";
import { Reservation, Schedule } from "../../API";

let eventGrid = 0;

export const createEventId = ()=> String(eventGrid++);


export const generateScheduleEvents = (schedules:Schedule[] | null):EventInput[] =>{
  if(schedules===null) return [] as EventInput[];
  return schedules.map(schedule=>{return{
    id: schedule.id,
    title: schedule.event?.name,
    start: schedule.start,
    end: schedule.end,
    display:'block',
    backgroundColor:schedule.isPublished?schedule.event?.color!:"#78909c",
    borderColor:schedule.isPublished?schedule.event?.color!:"#78909c"
  }})
}

export const generateReservationEvents = (reservations:Reservation[] | null):EventInput[] =>{
  if(reservations===null) return [] as EventInput[];
  return reservations.map(reservation=>{return{
    id: reservation.id,
    title: reservation.schedule?.event?.name,
    start: reservation.schedule?.start,
    end: reservation.schedule?.end,
    display:'block',
    backgroundColor: reservation.schedule?.event?.color!,
    borderColor: reservation.schedule?.event?.color!
  }})
}



export const useFormDialog = (selectInfo: DateSelectArg)=> {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FormDialog = () => (
    <div>
      <Button onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  return useCallback(()=>open ? <FormDialog/>:null,[open])
}