import { SelectChangeEvent, SelectProps } from "@mui/material";
import React from "react";
import {
  Controller,
  FieldPath,
  ControllerRenderProps,
  FieldValues,
  Control,
} from "react-hook-form";
import { SelectBox } from "./SelectBox";

type Props<T extends FieldValues> = {
  fieldName: FieldPath<T>;
  control: Control<T>;
  onChangeHandler?: (event:SelectChangeEvent<any>)=>void;
} & Omit<
  React.ComponentProps<typeof SelectBox>,
  "errorMessage" | keyof ControllerRenderProps
>;

export const SelectBoxControl = <T extends FieldValues>({
  fieldName,
  control,
  onChangeHandler,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field, fieldState }) => (
        <SelectBox
          errorMessage={fieldState.error?.message}
          {...field}
          {...props}
          onChange={(e) => {
            field.onChange(e)
            if(onChangeHandler) onChangeHandler(e);
          }}
        />
      )}
    />
  );
};
