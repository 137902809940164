import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import {
  CreateEventInput,
  CreateEventMutation,
  ListEventsQuery,
  Event,
  ModelEventFilterInput,
  DeleteEventInput,
  UpdateEventInput,
  UpdateEventMutation,
  GetEventQuery,
  DeleteEventMutation,
} from "../../API";
import {
  createEvent,
  deleteEvent,
  updateEvent,
} from "../../graphql/mutations";
import { _listEvents } from "../fixedQueries";
import { getEvent } from "../../graphql/queries";

export type CreateEventItems = {
  shopId: string;
  thumbnailName: string;
  thumbnailData: File;
  selectedTicketIds: string[];
  formState: CreateEventInput;
};
export type UpdateEventItems = {
  shopId: string;
  thumbnailName: string | null;
  thumbnailData: File | null;
  previousTicketIds: string[];
  selectedTicketIds: string[];
  formState: UpdateEventInput;
};
export type DeleteEventItems = {
  target: Event;
};

export const EventRepository = (_shopId: string | undefined) => {
  const shopId: string | undefined = _shopId;

  const fetch = () => {
    const listFilter: ModelEventFilterInput = {
      shopId: {
        eq: shopId,
      },
    } as ModelEventFilterInput;

    return new Promise<Event[]>(async (res, rej) => {
      if (shopId === undefined) rej();

      const result = (await API.graphql(
        graphqlOperation(_listEvents, { filter: listFilter })
      )) as GraphQLResult<ListEventsQuery>;

      if (result.data?.listEvents?.items) {
        const Events = result.data.listEvents.items as Event[];
        res(Events);
      } else {
        rej(new Error("empty"));
      }
    });
  };

  const get = (id: string) => {
    return new Promise<Event>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(getEvent, { id: id })
      )) as GraphQLResult<GetEventQuery>;

      if (result.data?.getEvent) {
        res(result.data?.getEvent as Event);
      } else {
        rej(new Error("empty"));
      }
    });
  };

  const create = (formState: CreateEventInput) => {
    const newEvent: CreateEventInput = { ...formState };
    return new Promise<Event>(async (res) => {
      const result = (await API.graphql(
        graphqlOperation(createEvent, { input: newEvent })
      )) as GraphQLResult<CreateEventMutation>;
      console.log(result);
      const Event = result.data?.createEvent as Event;
      res(Event);
    });
  };

  const update = (input: UpdateEventInput) => {
    return new Promise<Event>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(updateEvent, { input: input })
      )) as GraphQLResult<UpdateEventMutation>;
      if (result.data?.updateEvent) {
        res(result.data?.updateEvent);
      } else {
        rej(new Error("update error"));
      }
    });
  };

  const remove = (input: DeleteEventInput) => {
    return new Promise<Event>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(deleteEvent, { input: input })
      )) as GraphQLResult<DeleteEventMutation>;
      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.deleteEvent as Event);
      }
    });
  };

  return {
    fetch,
    get,
    create,
    update,
    remove,
  };
};
