import {
  Box,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import {
  Customer,
} from "../../API";


type CustomerFormProps = {
  customer: Customer;
  isLoading?: boolean;
  onSubmitFn: (input: any) => void;
};
export const CustomerForm = React.memo<CustomerFormProps>(
  ({ customer, onSubmitFn, isLoading }) => {

    return (
      <Box>
        <Stack spacing={4}>
          <TextField
            margin="dense"
            id="name"
            label="氏名"
            variant="outlined"
            disabled
            value={customer.name}
          />
          <TextField
            margin="dense"
            id="email"
            label="メールアドレス"
            variant="outlined"
            disabled
            value={customer.email}
          />
        </Stack>
      </Box>
    );
  }
);
