export enum ResourceOperation{
  FETCH_SHOP,
  FETCH_LESSON,
  FETCH_SCHEDULE,
  FETCH_RESERVATION,
  FETCH_TICKET,
  FETCH_CUSTOMER,
  GET_SHOP,
  GET_LESSON,
  GET_SCHEDULE,
  GET_RESERVATION,
  GET_TICKET,
  GET_CUSTOMER,  
  CREATE_LESSON,
  CREATE_SCHEDULE,
  CREATE_SCHEDULES,
  CREATE_RESERVATION,
  CREATE_TICKET,
  CREATE_CUSTOMER,
  UPDATE_SHOP,
  UPDATE_LESSON,
  UPDATE_SCHEDULE,
  UPDATE_RESERVATION,
  UPDATE_TICKET,
  UPDATE_CUSTOMER,
  DELETE_LESSON,
  DELETE_SCHEDULE,
  DELETE_RESERVATION,
  DELETE_TICKET,
  DELETE_CUSTOMER,
  SET_TRANSACTION,
  SET_ZOOM_INFO,
  RESET_ALL
}