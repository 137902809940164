import { getShop } from "../../graphql/queries";
import { GetShopQuery, Shop, UpdateShopInput, UpdateShopMutation } from "../../API";
import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { updateShop } from "../../graphql/mutations";
export const ShopRepository = (_shopId: string | undefined) => {
  const shopId: string | undefined = _shopId;
  const fetch = () => {
    return new Promise<Shop>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(getShop, { id: shopId })
      )) as GraphQLResult<GetShopQuery>;
      if(result.data?.getShop){
        res(result.data?.getShop)
      }else{
        rej(new Error('empty'))
      }
    });
  };

  const update = (input:UpdateShopInput) =>{
    return new Promise<Shop>(async(res, rej)=>{
      const result = await API.graphql(graphqlOperation(updateShop,{input:input})) as GraphQLResult<UpdateShopMutation>
      if(result.data?.updateShop){
        res(result.data?.updateShop)
      }else{
        rej(new Error('update error'))
      }
    })
  }

  return {
    fetch,
    update
  };
};
