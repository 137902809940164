import { createContext, useReducer, useMemo, useContext } from "react";
import { AppRoutes, NavigationRoutes } from "./route";
import { AppNavigationRoute } from "./types";

type PageNavigationState = {
  childrenNavigationRoute?: AppNavigationRoute;
  routeChildrenIndex?: number;
  currentRoute?: string;
  parentRoute?: string;
};

const initialState: PageNavigationState = {
  routeChildrenIndex: 0,
};

export enum PageNavigationEvent {
  onClickedHeaderNavi,
}

type PageNavigationActionType = {
  type: PageNavigationEvent.onClickedHeaderNavi;
  payload: {
    childrenNavigationRoute?: AppNavigationRoute;
    currentRoute: string;
    parentRoute?: string;
  };
};

const ApplicationPreferenceContext = createContext(
  {} as {
    pageNavigationState: PageNavigationState;
    pageNavigationDispatcher: React.Dispatch<PageNavigationActionType>;
  }
);

const reducer = (
  pageNavigationState: PageNavigationState,
  action: PageNavigationActionType
) => {
  switch (action.type) {
    case PageNavigationEvent.onClickedHeaderNavi:
      return {
        childrenNavigationRoute: action.payload.childrenNavigationRoute,
        currentRoute: action.payload.currentRoute,
        parentRoute: action.payload.parentRoute,
      };
    default:
      return pageNavigationState;
  }
};

type Props = {
  children?: React.ReactNode;
};

export const PageNavigationProvider: React.FC<Props> = (props) => {
  const [pageNavigationState, pageNavigationDispatcher] = useReducer(
    reducer,
    initialState
  );
  const value = useMemo(
    () => ({ pageNavigationState, pageNavigationDispatcher }),
    [pageNavigationState]
  );
  return <ApplicationPreferenceContext.Provider value={value} {...props} />;
};

export const usePageNavigation = () => {
  const context = useContext(ApplicationPreferenceContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useApplicaitonPreference must be within a ApplicationPreferenceProvider."
    );
  }
  return context;
};
