import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventInput,
  MoreLinkArg,
  MoreLinkContentArg,
  
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Typography } from "@mui/material";

type ScheduleCalenderProps = {
  dateSelectHandler:(events: EventApi[])=>void,
  eventClickHandler:(clickInfo: EventClickArg)=>void,
  areaClickHandler:(selectInfo: DateSelectArg)=>void,
  renderContent:(eventContent: EventContentArg)=>JSX.Element,
  schedules:EventInput[],
  calenderRef:React.MutableRefObject<FullCalendar>,
};

type ReservationCalenderProps = {
  dateSelectHandler:(events: EventApi[])=>void,
  eventClickHandler:(clickInfo: EventClickArg)=>void,
  areaClickHandler:(selectInfo: DateSelectArg)=>void,
  renderContent:(eventContent: EventContentArg)=>JSX.Element,
  reservations:EventInput[],
};

export const ScheduleCalender:React.FC<ScheduleCalenderProps> = ({
  dateSelectHandler,
  eventClickHandler,
  areaClickHandler,
  renderContent,
  schedules,
  calenderRef
}) => {

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      ref={calenderRef}
      editable={false}
      droppable={false}
      
      navLinks={true}
      businessHours={false}
      initialEvents={schedules}
      //events={events}
      locales={allLocales}
      locale="ja"
      eventsSet={dateSelectHandler}
      selectable={true}
      select={areaClickHandler}
      eventClick={eventClickHandler}
      eventContent={renderContent}
      dayMaxEventRows={5}
      moreLinkClick="day"
      moreLinkContent={(e)=>renderLinkContent(e)}
      headerToolbar={{
        start: "prev,next today",
        center: "title",
        end: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      
    />
  );
};

const renderLinkContent = (eventInfo: MoreLinkContentArg) =>{
  return <>
    <b>{eventInfo.num}件のスケジュール</b>
  </>
}


export const ReservationCalender:React.FC<ReservationCalenderProps> = ({
  dateSelectHandler,
  eventClickHandler,
  areaClickHandler,
  renderContent,
  reservations,
}) => {

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      editable={false}
      droppable={false}
      navLinks={false}
      businessHours={false}
      initialEvents={reservations}
      //events={events}
      locales={allLocales}
      locale="ja"
      eventsSet={dateSelectHandler}
      selectable={true}
      select={areaClickHandler}
      eventClick={eventClickHandler}
      eventContent={renderContent}
      headerToolbar={{
        start: "prev,next,today",
        center: "title",
        end: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      
    />
  );
};

