import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UpdateShopInput } from "../../API";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  StoreResource,
  StoreTransactionStatus,
  useApplicationStore,
} from "../../provider/ApplicationStoreProvider";

import { UpdateEntityModel } from "../../provider/reducer/RepositoryAsyncReducer";
import { useSnackbar } from "notistack";
import { ResourceOperation } from "../../util/graphqlEvents";
const formSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
  email: yup.string(),
  address: yup.string(),
  tel: yup.string().required(),
  profile: yup.string().nullable(),
});

export const ProfilePage = () => {
  const { store, dispatch } = useApplicationStore();

  const [isLoading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const initialFormState = {
    id: store.shop?.id,
    name: store.shop?.name,
    email: store.shop?.email,
    address: store.shop?.address,
    tel: store.shop?.tel,
  } as UpdateShopInput;
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<UpdateShopInput>({
    resolver: yupResolver(formSchema),
    defaultValues: initialFormState,
  });

  const onSubmit: SubmitHandler<UpdateShopInput> = (data) => {
    setLoading(true);
    enqueueSnackbar("会社情報の編集を開始しました。", {
      variant: "info",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    dispatch({
      type: ResourceOperation.SET_TRANSACTION,
      payload: {
        resource: StoreResource.shop,
        method: "update",
        status: StoreTransactionStatus.processing,
      },
    });
    dispatch({
      type: "UPDATE_ENTITY_DATA",
      payload: {
        shopId: data.id,
        entityName: "Shop",
        input: data,
      } as UpdateEntityModel,
    });
  };
  useEffect(() => {
    if (
      store.transaction?.resource === StoreResource.shop &&
      store.transaction.method === "update" &&
      store.transaction.status === StoreTransactionStatus.success
    ) {
      setLoading(false);
      enqueueSnackbar("会社情報の更新が完了しました。", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });

      dispatch({
        type: ResourceOperation.SET_TRANSACTION,
        payload: {
          resource: StoreResource.shop,
          status: StoreTransactionStatus.idle,
        },
      });
    }

    if (store.shop !== null) {
      setValue("id", store.shop.id);
      setValue("name", store.shop.name);
      setValue("email", store.shop.email);
      setValue("address", store.shop.address);
      setValue("tel", store.shop.tel);
    }
  }, [dispatch, enqueueSnackbar, setValue, store.shop, store.transaction]);

  return (
    <Card>
      <Box py={5} px={4}>
        <Typography variant="h3">会社情報</Typography>
        <Divider sx={{ mt: 1, mb: 2 }} />
        {store.shop !== null ? (
          <Box>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="name"
                  label="会社名"
                  fullWidth
                  variant="outlined"
                  {...register("name")}
                  error={"name" in errors}
                  placeholder="Name"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="address"
                  label="住所"
                  fullWidth
                  variant="outlined"
                  {...register("address")}
                  error={"address" in errors}
                  placeholder="住所"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField          
                  margin="dense"
                  id="email"
                  label="メールアドレス"
                  fullWidth
                  variant="outlined"
                  disabled
                  {...register("email")}
                  error={"email" in errors}
                  placeholder="example@feelfree2yoga.net"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="tel"
                  label="電話番号"
                  fullWidth
                  variant="outlined"
                  disabled
                  {...register("tel")}
                  error={"tel" in errors}
                  placeholder="Name"
                />
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Button
                variant="contained"
                sx={{ width: "100px" }}
                onClick={handleSubmit(onSubmit)}
              >
                更新する
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        )}
      </Box>
    </Card>
  );
};
