import { Box, Typography, Divider, Skeleton} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { StoreResource, StoreTransactionStatus, useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { useParams } from "react-router-dom";
import { Customer, UpdateCustomerInput } from "../../API";
import { CustomerForm } from "../../components/Form/CustomerForm";
import { ResourceOperation } from "../../util/graphqlEvents";
import { UpdateEntityModel } from "../../provider/reducer/RepositoryAsyncReducer";
import { useSnackbar } from "notistack";

export const CustomerEditPage = () => {
  const { store, dispatch } = useApplicationStore();
  const [customer, setCustomer] = useState<Customer>();
  const { customerId } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    (input: UpdateCustomerInput) => {
      setLoading(true);
      enqueueSnackbar("顧客情報の更新を開始しました。", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      dispatch({
        type: ResourceOperation.SET_TRANSACTION,
        payload: {
          resource: StoreResource.customer,
          method: "update",
          status: StoreTransactionStatus.processing
        }
      })
      dispatch({
        type: "UPDATE_ENTITY_DATA",
        payload: {
          shopId: store.shop?.id,
          entityName: "Customer",
          input: input
        } as UpdateEntityModel,
      });
    },
    [dispatch, store.shop?.id]
  );


  useEffect(() => {
    if (
      store.transaction?.resource === StoreResource.customer &&
      store.transaction.method === "update" &&
      store.transaction.status === StoreTransactionStatus.success
    ) {
      setLoading(false);
      enqueueSnackbar("顧客の編集が完了しました。", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      dispatch({
        type: ResourceOperation.SET_TRANSACTION,
        payload: {
          resource: StoreResource.customer,
          status: StoreTransactionStatus.idle
        } 
      })
    }

    if (store.shop !== null && store.customers === null) {
      dispatch({
        type: "FETCH_CUSTOMER_DATA",
        payload: { shopId: store.shop.id },
      });
    }
    if (store.customers !== null) {
      setCustomer(store.customers.find((c) => c.id === customerId));
    }
  }, [store.shop, store.customers, store.transaction ,dispatch, customerId]);

  return customer ? (
    <Box>
      <Typography variant="h2" fontWeight={600}>
        {`顧客ID:${customer.id}の編集`}
      </Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <CustomerForm customer={customer} onSubmitFn={onSubmit} isLoading={isLoading} />
    </Box>
  ) : (
    <Box>
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
  </Box>
  );
};
