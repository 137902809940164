import { createTheme, PaletteMode } from "@mui/material";
import { teal, grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    darker?: string;
  }
  interface TypeBackground {
    darker?: string;
  }
  interface TypographyVariants {
    error: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    error?: React.CSSProperties;
  }
  interface ChipVariants {
    selected: React.CSSProperties;
    unselected: React.CSSProperties;
  }
  interface ChipVariantsOptions {
    selected?: React.CSSProperties;
    unselected?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    selected: true;
    unselected: true;
  }
}

export const BaseTheme = (mode: PaletteMode) =>
  createTheme({
    palette: {
      primary: {
        main: mode === "light" ? teal["400"] : "#6184d8",
        light: mode === "light" ? teal["100"] : "#8da6e2",
        dark: mode === "light" ? teal["800"] : "#6184d8",
        contrastText: "#fcf7f8",
      },
      secondary: {
        main: "#000",
        light: "#fcf7f8",
        dark: "#f9f0f2",
        contrastText: teal["500"],
      },
      background: {
        default: mode === "light" ? "#fcf7f8" : "#1a1423",
        paper: mode === "light" ? "#fcf7f8" : "#221e2a",
      },
      neutral:{
        main: mode === "light" ? teal["400"] : "#6184d8",
        light: mode === "light" ? teal["100"] : "#8da6e2",
        dark: mode === "light" ? teal["800"] : "#6184d8",
        contrastText: "#fcf7f8",
      },
      text: {
        primary: mode === "light" ? "#1a1423" : "#fcf7f8",
        secondary: mode === "light" ? "#1a1423" : "#f9f0f2",
      },
      mode: mode,
    },
    typography: {
      fontFamily: ['"Noto Sans JP"', '"Helvetica"', "Arial", "sans-serif"].join(
        ","
      ),
      h1: {
        fontSize: 24,
      },
      h2: {
        fontSize: 21,
      },
      h3: {
        fontSize: 18,
      },
      h4: {
        fontSize: 16,
      },
      h5: {
        fontSize: 14,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      subtitle1: {
        fontSize: 14,
      },
      subtitle2: {
        fontSize: 12,
      },
      error: {
        fontSize: 14,
        color: mode === "light" ? "#df1b41" : "#fe87a1",
      },
    },
    components: {
      MuiChip: {
        variants: [
          { props: { variant: "selected" }, style: {} },
          {
            props: { variant: "unselected" },
            style: {
              background: mode === "light" ? "#fcf7f8" : "#1a1423",
              color: mode !== "light" ? "#fcf7f8" : "#1a1423",
              ":hover":{
                background: mode === "light" ? grey["300"] : "#8da6e2",
              }
            },
          },
        ],
      },
    },
  });
