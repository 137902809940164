import { Stack } from "@mui/material";
import React from "react";
import { HeaderNaviItem } from "./HeaderNaviItem";

import { AppNavigationProps } from "../types";

export const HeaderNavi = React.memo<AppNavigationProps>(
  ({ navigationRoute }) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyItems="flex-start"
        spacing={1}
        px={2}
        py={1}
        maxWidth={1690}
        width="100%"
        mx="auto"
      >
        {navigationRoute.depth === 0
          ? [navigationRoute, ...navigationRoute.children!]
              ?.filter((item) => item.showVisibility)
              .map((item, idx) => (
                <HeaderNaviItem
                  key={`link-list-item-${idx}`}
                  navigationRoute={item}
                  color="primary"
                  size="small"
                />
              ))
          : null}
      </Stack>
    );
  }
);
