import {
  formatDate,
  formatIsoTimeString,
  formatRange,
} from "@fullcalendar/react";
import {
  Box,
  Card,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ModalProvider from "mui-modal-provider";
import { useEffect, useState } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { Reservation, Schedule } from "../../API";
import { GroupedReservationPanel } from "../../components/Panel/GroupedReservationPanel";
import { ReservationPerSchedulePanel } from "../../components/Panel/ReservationPerSchedulePanel";
import { ScheduleReservedCustomerTable } from "../../components/Table/ScheduleReservedCustomerTable";
import {
  StoreTransactionStatus,
  useApplicationStore,
} from "../../provider/ApplicationStoreProvider";
import {
  PageNavigationEvent,
  usePageNavigation,
} from "../../theme/simple_admin/PageNavigation/provider";
import {
  AppRoutes,
  AppRoute,
} from "../../theme/simple_admin/PageNavigation/route";

export const ReservationPerDayPage = () => {
  const { store, dispatch } = useApplicationStore();
  const { pageNavigationDispatcher } = usePageNavigation();
  const location = useLocation();
  const routes = matchRoutes(AppRoutes, location);
  const currentRoute =
    routes?.length! > 0
      ? (routes![0].route as AppRoute)
      : ({ path: "/" } as AppRoute);

  const [filteredSchedules, setSchedules] = useState<Schedule[]>();
  const { day } = useParams();

  useEffect(() => {
    //if (!isLoaded) fetchTodos();
    if (store.shop !== null) {
      if (store.schedules === null) {
        dispatch({
          type: "FETCH_RESERVATION_DATA",
          payload: { shopId: store.shop.id },
        });
      } else {
        setSchedules(
          store.schedules?.filter(
            (schedule) =>
              new Date(schedule.start).toISOString().split("T")[0] === day
          )
        );
        pageNavigationDispatcher({
          type: PageNavigationEvent.onClickedHeaderNavi,
          payload: {
            currentRoute: currentRoute.path,
            parentRoute: currentRoute.parentPath,
          },
        });
        if (store.transaction?.method === "update") {
          console.log("update schedule");
        }
      }
    }
  }, [
    store.shop,
    day,
    store.transaction,
    store.schedules,
    dispatch,
    pageNavigationDispatcher,
    currentRoute.path,
    currentRoute.parentPath,
  ]);
  return (
    <ModalProvider>
      <Box>
        <Typography variant="h2" fontWeight={600}>
          {formatDate(day!, {
            year: "numeric",
            month: "long",
            day: "numeric",
            locale: "ja",
          })}
          の予約一覧
        </Typography>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack spacing={2}>
          {filteredSchedules?.length! > 0 ? (
            filteredSchedules?.map((schedule) => (
              <ReservationPerSchedulePanel
                key={schedule.id}
                schedule={schedule}
              />
            ))
          ) : (
            <Card variant="outlined" elevation={0}>
              <Box p={1}>
                <Typography variant="body1">
                  スケジュールがありません。
                </Typography>
              </Box>
            </Card>
          )}
        </Stack>
      </Box>
    </ModalProvider>
  );
};
