import { useMemo, useContext, createContext, Reducer } from "react";
import { useReducerAsync } from "use-reducer-async";
import {
  Event,
  Schedule,
  Shop,
  Customer,
  Reservation,
} from "../API";
import { AsyncAction, asyncActionHandlers } from "./reducer/RepositoryAsyncReducer";
import { ACTIONTYPE, storeReducer } from "./reducer/StoreReducer";

export enum StoreResource {
  "shop",
  "customer",
  "schedule",
  "reservation",
  "event",
  "ticket"
}

export enum StoreTransactionStatus {
  "idle",
  "processing",
  "success",
  "error"
} 

export type ApiTransaciton ={
  resource: StoreResource,
  method?: string,
  status:  StoreTransactionStatus,
  data?: Schedule | Schedule[] | Event | Shop | Customer
}

export type STORE = {
  shop: Shop | null;
  customers: Customer[] | null;
  schedules: Schedule[] | null;
  reservations: Reservation[] | null;
  events: Event[] | null;
  transaction?: ApiTransaciton
};

export const ApplicationStoreContext = createContext(
  {} as {
    store: STORE;
    dispatch: React.Dispatch<ACTIONTYPE | AsyncAction>;
  }
);

type Props = {
  children?: React.ReactNode;
};

const initialStore: STORE = {
  shop: null,
  customers: null,
  schedules: null,
  reservations: null,
  events: null,
};

export const ApplicationStoreProvider: React.FC<Props> = (props) => {
  const [store, dispatch] = useReducerAsync<
    Reducer<STORE, ACTIONTYPE>,
    AsyncAction,
    ACTIONTYPE | AsyncAction
  >(storeReducer, initialStore, asyncActionHandlers);
  const value = useMemo(() => ({ store, dispatch }), [dispatch, store]);

  return <ApplicationStoreContext.Provider value={value} {...props} />;
};

export const useApplicationStore = () => {
  const context = useContext(ApplicationStoreContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useApplicationStore must be within a ApplicationStoreProvider."
    );
  }
  return context;
};
