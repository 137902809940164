import {
  Box,
  Stack,
  Typography,
  Divider,
  Button,
  CssBaseline,
  IconButton,
  Avatar,
  Skeleton,
} from "@mui/material";
import {
  PreferenceEvent,
  useApplicationPreference,
} from "../../provider/ApplicationPreferenceProvider";
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { HeaderNavi } from "./PageNavigation/HeaderNavi/HeaderNavi";
import { NavigationRoutes } from "./PageNavigation/route";
import ThemeModeSwitch from "../../components/Switch/ThemeModeSwitch";
import {
  PageNavigationEvent,
  usePageNavigation,
} from "./PageNavigation/provider";
import { ResourceOperation } from "../../util/graphqlEvents";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";

type HeaderProps = {
  shopId?: string;
  shopName?: string;
};

export const Header = React.memo<HeaderProps>(({ shopId, shopName }) => {
  const { preferenceDispatcher } = useApplicationPreference();
  const {dispatch} = useApplicationStore();
  const { pageNavigationState, pageNavigationDispatcher } = usePageNavigation();
  const navigate = useNavigate();
  const defaultMode = localStorage.getItem("themeMode") ?? "light";
  const [isLightMode, setLightMode] = useState<boolean>(
    defaultMode === "light" ? true : false
  );
  const modeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLightMode(event.target.checked);
    preferenceDispatcher({
      type: PreferenceEvent.SET_MODE,
      payload: event.target.checked ? "light" : "dark",
    });
  };
  return (
    <Box component="header">
      <Stack
        direction="row"
        alignItems="center"
        px={2}
        pt={1}
        pb={0.7}
        width="92.5%"
        maxWidth={1690}
        mx="auto"
      >
        <Box flexGrow={1}>
          {shopId && shopName ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                size="small"
                onClick={() => {
                  pageNavigationDispatcher({
                    type: PageNavigationEvent.onClickedHeaderNavi,
                    payload: { currentRoute: `/u/${shopId}/config/profile` },
                  });
                  navigate(`/u/${shopId}/config/profile`);
                }}
              >
                <Avatar sx={{ width: 28, height: 28 }}>
                  {shopName.charAt(0)}
                </Avatar>
              </IconButton>
              <Typography variant="caption">{shopName}</Typography>
            </Stack>
          ) : (
            <Skeleton />
          )}
        </Box>
        <Box>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              Auth.signOut().then(() => {
                dispatch({
                  type: ResourceOperation.RESET_ALL,
                  payload: null
                })
                navigate("/");
              });
            }}
          >
            ログアウト
          </Button>
        </Box>
      </Stack>

      <Divider />
      <Stack
        width="92.5%"
        maxWidth={1690}
        mx="auto"
        direction="row"
        justifyContent="space-between"
        align-items="center"
      >
        <Box>
          {shopId && shopName ? (
            <HeaderNavi
              navigationRoute={NavigationRoutes(shopId)}
              shopId={shopId}
            />
          ) : (
            <Skeleton />
          )}
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <ThemeModeSwitch onChange={modeHandler} checked={isLightMode} />
        </Box>
      </Stack>
      <Divider />
    </Box>
  );
});
