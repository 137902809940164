import { AppNavigationRoute } from "./types";
export type AppRoute = {
  path: string;
  parentPath?: string;
};
export const AppRoutes: AppRoute[] = [
  { path: "/u/:userId" },
  { path: "/u/:userId/dashboard" },
  { path: "/u/:userId/config/profile" },
  { path: "/u/:userId/reservations" },
  {
    path: "/u/:userId/reservation/s/:scheduleId",
    parentPath: "/u/:userId/reservations",
  },
  {
    path: "/u/:userId/reservation/:day",
    parentPath: "/u/:userId/reservations",
  },
  { path: "/u/:userId/schedules" },
  { path: "/u/:userId/events" },
  { path: "/u/:userId/event/new", parentPath: "/u/:userId/events" },
  { path: "/u/:userId/event/:eventId", parentPath: "/u/:userId/events" },
  { path: "/u/:userId/customers" },
  {
    path: "/u/:userId/customer/:customerId",
    parentPath: "/u/:userId/customers",
  }
];
export const NavigationRoutes = (userId: string) => {
  return {
    name: "ダッシュボード",
    path: `/u/${userId}/dashboard`,
    route: "/u/:userId/dashboard",
    showVisibility: true,
    depth: 0,
    children: [
      {
        name: "予約",
        path: `/u/${userId}/reservations`,
        route: "/u/:userId/reservations",
        depth: 1,
        slug: "reservations",
        showVisibility: true,
        children: [
          {
            name: "日別予約一覧",
            route: `/u/:userId/reservation/:day`,
            depth: 2,
            slug: "reservations",
            showVisibility: false,
          },
        ],
      },
      {
        name: "スケジュール",
        path: `/u/${userId}/schedules`,
        route: `/u/:userId/schedules`,
        depth: 1,
        slug: "schedules",
        showVisibility: true,
      },
      {
        name: "イベント",
        path: `/u/${userId}/events`,
        route: `/u/:userId/events`,
        depth: 1,
        slug: "events",
        showVisibility: true,
        children: [
          {
            name: "新規イベント作成",
            path: `/u/${userId}/event/new`,
            route: `/u/:userId/event/new`,
            depth: 1,
            slug: "events",
            showVisibility: true,
          },
        ],
      },
      {
        name: "顧客",
        path: `/u/${userId}/customers`,
        route: `/u/:userId/customers`,
        depth: 1,
        slug: "customers",
        showVisibility: true,
      },

      {
        name: "設定",
        path: `/u/${userId}/config/profile`,
        route: `/u/:userId/config/profile`,
        depth: 1,
        slug: "config",
        showVisibility: false,
        children: [
          {
            name: "会社情報",
            path: `/u/${userId}/config/profile`,
            route: `/u/:userId/config/profile`,
            depth: 1,
            slug: "config",
            showVisibility: true,
          }
        ],
      },
    ],
  } as AppNavigationRoute;
};
