import { Shop, Customer, Event, Schedule, Reservation } from "../../API";
import { ResourceOperation } from "../../util/graphqlEvents";
import {
  ApiTransaciton,
  STORE,
  StoreResource,
  StoreTransactionStatus,
} from "../ApplicationStoreProvider";

export type ACTIONTYPE =
  | { type: ResourceOperation.FETCH_SHOP; payload: Shop | null }
  | { type: ResourceOperation.FETCH_CUSTOMER; payload: Customer[] }
  | { type: ResourceOperation.FETCH_LESSON; payload: Event[] }
  | { type: ResourceOperation.FETCH_SCHEDULE; payload: Schedule[] }
  | { type: ResourceOperation.FETCH_RESERVATION; payload: Reservation[] }
  | { type: ResourceOperation.GET_SHOP; payload: Shop }
  | { type: ResourceOperation.GET_CUSTOMER; payload: Customer }
  | { type: ResourceOperation.GET_LESSON; payload: Event }
  | { type: ResourceOperation.GET_SCHEDULE; payload: Schedule }
  | { type: ResourceOperation.GET_RESERVATION; payload: Reservation }
  | { type: ResourceOperation.CREATE_CUSTOMER; payload: Customer }
  | { type: ResourceOperation.CREATE_LESSON; payload: Event }
  | { type: ResourceOperation.CREATE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperation.CREATE_SCHEDULES; payload: Schedule[] }
  | { type: ResourceOperation.CREATE_RESERVATION; payload: Reservation }
  | { type: ResourceOperation.UPDATE_SHOP; payload: Shop }
  | { type: ResourceOperation.UPDATE_CUSTOMER; payload: Customer }
  | { type: ResourceOperation.UPDATE_LESSON; payload: Event }
  | { type: ResourceOperation.UPDATE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperation.UPDATE_RESERVATION; payload: Reservation }
  | { type: ResourceOperation.DELETE_CUSTOMER; payload: Customer }
  | { type: ResourceOperation.DELETE_LESSON; payload: Event }
  | { type: ResourceOperation.DELETE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperation.DELETE_RESERVATION; payload: Reservation }
  | { type: ResourceOperation.SET_TRANSACTION; payload: ApiTransaciton }
  | { type: ResourceOperation.RESET_ALL; payload: null };

export const storeReducer = (store: STORE, action: ACTIONTYPE) => {
  switch (action.type) {
    case ResourceOperation.FETCH_SHOP:
      return {
        ...store,
        shop: action.payload,
        transaction: {
          resource: StoreResource.shop,
          status: StoreTransactionStatus.success,
        },
      };
    case ResourceOperation.FETCH_CUSTOMER:
      return {
        ...store,
        customers: action.payload,
        transaction: {
          resource: StoreResource.customer,
          status: StoreTransactionStatus.success,
        },
      };
    case ResourceOperation.FETCH_LESSON:
      return {
        ...store,
        events: action.payload.sort((a, b) => (a.order || 0) - (b.order || 0)),
        transaction: {
          resource: StoreResource.event,
          status: StoreTransactionStatus.success,
        },
      };
    case ResourceOperation.FETCH_SCHEDULE:
      return {
        ...store,
        schedules: action.payload,
        transaction: {
          resource: StoreResource.schedule,
          status: StoreTransactionStatus.success,
        },
      };
    case ResourceOperation.FETCH_RESERVATION:
      return {
        ...store,
        reservations: action.payload,
        transaction: {
          resource: StoreResource.reservation,
          status: StoreTransactionStatus.success,
        },
      };
    case ResourceOperation.CREATE_LESSON:
      return {
        ...store,
        events: [...store.events!, action.payload as Event].sort(
          (a, b) => (a.order || 0) - (b.order || 0)
        ),
        transaction: {
          resource: StoreResource.event,
          method: "create",
          status: StoreTransactionStatus.success,
          data: action.payload,
        },
      };
    case ResourceOperation.CREATE_SCHEDULE:
      return {
        ...store,
        schedules: [...store.schedules!, action.payload as Schedule],
        transaction: {
          resource: StoreResource.schedule,
          method: "create",
          status: StoreTransactionStatus.success,
          data: [action.payload],
        },
      };
    case ResourceOperation.GET_SCHEDULE:
      return {
        ...store,
        schedules: store.schedules!.map((schedule) =>
          schedule.id !== action.payload.id ? schedule : action.payload
        )
      };
    case ResourceOperation.UPDATE_SCHEDULE:
      return {
        ...store,
        schedules: store.schedules!.map((schedule) =>
          schedule.id !== action.payload.id ? schedule : action.payload
        ),
        transaction: {
          resource: StoreResource.schedule,
          method: "update",
          status: StoreTransactionStatus.success,
          data: action.payload,
        },
      };
    case ResourceOperation.CREATE_SCHEDULES:
      return {
        ...store,
        schedules: [...store.schedules!, ...action.payload],
        transaction: {
          resource: StoreResource.schedule,
          method: "create",
          status: StoreTransactionStatus.success,
          data: action.payload,
        },
      };

    case ResourceOperation.DELETE_SCHEDULE:
      return {
        ...store,
        schedules: store.schedules?.filter(
          (schedule) => schedule.id !== action.payload.id
        )!,
        transaction: {
          resource: StoreResource.schedule,
          method: "delete",
          status: StoreTransactionStatus.success,
          data: action.payload,
        },
      };
    case ResourceOperation.UPDATE_SHOP:
      return {
        ...store,
        shop: action.payload,
        transaction: {
          resource: StoreResource.shop,
          method: "update",
          status: StoreTransactionStatus.success,
          data: action.payload,
        },
      };
    case ResourceOperation.UPDATE_LESSON:
      return {
        ...store,
        events: store
          .events!.map((event) =>
            event.id !== action.payload.id ? event : action.payload
          )
          .sort((a, b) => (a.order || 0) - (b.order || 0)),
        transaction: {
          resource: StoreResource.event,
          method: "update",
          status: StoreTransactionStatus.success,
          data: action.payload,
        },
      };
    case ResourceOperation.DELETE_LESSON:
      return {
        ...store,
        events: store.events!.filter((event) => event.id !== action.payload.id),
        transaction: {
          resource: StoreResource.event,
          method: "delete",
          status: StoreTransactionStatus.success,
        },
      };
    case ResourceOperation.UPDATE_CUSTOMER:
      return {
        ...store,
        customer: action.payload,
        transaction: {
          resource: StoreResource.customer,
          method: "update",
          status: StoreTransactionStatus.success,
          data: action.payload,
        },
      };
    case ResourceOperation.UPDATE_RESERVATION:
      return {
        ...store,
        reservations:
          store.reservations?.map((r) =>
            r.id === action.payload.id ? action.payload : r
          ) ?? null,
      };
    case ResourceOperation.SET_TRANSACTION:
      return {
        ...store,
        transaction: action.payload,
      };
    case ResourceOperation.RESET_ALL:
      return {
        shop: null,
        customers: null,
        schedules: null,
        reservations: null,
        events: null,
        tickets: null,
      };
    default:
      return store;
  }
};
