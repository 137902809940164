import { Amplify, I18n  } from "aws-amplify";
import { AppRouter } from './route';
import awsconfig from "./aws-exports";
import { ApplicationPreferenceProvider } from './provider/ApplicationPreferenceProvider';
import { ApplicationStoreProvider } from './provider/ApplicationStoreProvider';
import { Authenticator } from '@aws-amplify/ui-react';
import { SnackbarProvider } from 'notistack';
import { translations } from '@aws-amplify/ui';
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

Amplify.configure(awsconfig);

const App = () => {
  return (
    <Authenticator.Provider>
      <ApplicationStoreProvider>
        <ApplicationPreferenceProvider>
          <SnackbarProvider>
            <AppRouter />
          </SnackbarProvider>
        </ApplicationPreferenceProvider>
      </ApplicationStoreProvider>
    </Authenticator.Provider>
  );
};

export default App;

