import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CreatePreReservationInput,
  CustomerInput,
  ReservationStatus,
  Schedule,
} from "../../../API";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useApplicationStore } from "../../../provider/ApplicationStoreProvider";
import { SelectBoxControl } from "../../Input/SelectBox/SelectBoxControl";
import { formatRange } from "@fullcalendar/react";

const formSchema = yup.object({
  customerId: yup.string().required("顧客の選択は必須です。"),
});
type Props = DialogProps & {
  title: string;
  description: string;
  schedule: Schedule;
  onCancel: () => void;
  onConfirm: (
    createPreReservationInput: CreatePreReservationInput,
    customerInput: CustomerInput
  ) => void;
};

type FormProps = {
  customerId: string;
};

export const CreatePreReservationDialog = React.memo<Props>(
  ({ title, description, schedule, onCancel, onConfirm, ...props }) => {
    const { store } = useApplicationStore();
    const {
      handleSubmit,
      trigger,
      watch,
      setValue,
      formState: { errors },
      control,
    } = useForm<FormProps>({
      resolver: yupResolver(formSchema),
      defaultValues: {
        customerId: ""
      },
    });
    const onSubmit: SubmitHandler<FormProps> = (data) => {

      const createPreReservationInput: CreatePreReservationInput = {
        shopId: schedule.shopId,
        scheduleId: schedule.id,
        status: ReservationStatus.Entry,
      };
      const customer = store.customers?.find(c=>c.id === data.customerId)
      const customerInput:CustomerInput ={
        shopId: customer?.shopId!,
        id: customer?.id!,
        lineId: customer?.lineId!,
        name: customer?.name!,
        email: customer?.name!,
        exists: true
      }
      onConfirm(createPreReservationInput, customerInput);
    };

    return (
      <Dialog fullWidth={true} maxWidth="sm" {...props}>
        <Box p={1}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body2" mb={1}>
              {description}
            </Typography>
            <Stack spacing={3} py={1}>
              <Box>
                <Typography variant="caption">対象イベント</Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{schedule.event?.name}</Typography>

                  <Chip
                    size="small"
                    label={`開催期間:${formatRange(
                      schedule.start,
                      schedule.end,
                      {
                        hour: "numeric",
                        minute: "numeric",
                        locale: "jp",
                        separator: "-",
                      }
                    )}`}
                  />
                </Stack>
              </Box>
              <SelectBoxControl
                control={control}
                labelId="select-customer-label"
                fieldName="customerId"
                label={"予約する顧客"}
                required
                onChangeHandler={(e) => {
                  trigger("customerId");
                }}
                items={
                  store.customers?.map((customer) => {
                      return {
                        key: customer.id,
                        value: customer.id,
                        label: customer.name!,
                      };
                    })!
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>キャンセル</Button>
            <Button
              variant="contained"
              disableElevation
              onClick={handleSubmit(onSubmit)}
            >
              予約する
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
);
