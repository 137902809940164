import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Switch,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useModal } from "mui-modal-provider";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DeleteScheduleInput, UpdateScheduleInput } from "../../../API";
import { useApplicationStore } from "../../../provider/ApplicationStoreProvider";
import { EditScheduleBySelectedDateProps } from "../type";
import { DeleteItemDialog } from "../Common/DeleteItemDialog";
import {
  DeleteEntityModel,
  UpdateEntityModel,
} from "../../../provider/reducer/RepositoryAsyncReducer";

const formSchema = yup.object({
  shopId: yup.string().required(),
  eventId: yup.string().required("イベントの選択は必須です。"),
  start: yup.string().required("イベント開始時間を設定してください。"),
  end: yup.string().required("イベント終了時間の選択は必須です。"),
  isPublished: yup.boolean(),
});

export const EditScheduleDialog: React.FC<EditScheduleBySelectedDateProps> = ({
  title,
  description,
  selectedSchedule,
  onCancel,
  onConfirm,
  ...props
}) => {
  const { store, dispatch } = useApplicationStore();
  const [startTime, setStartTime] = useState<Date | null>(
    new Date(selectedSchedule.start)
  );
  const [endTime, setEndTime] = useState<Date | null>(
    new Date(selectedSchedule.end)
  );

  const { showModal } = useModal();

  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<UpdateScheduleInput>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      id: selectedSchedule.id,
      shopId: selectedSchedule.shopId,
      eventId: selectedSchedule.eventId,
      start: selectedSchedule.start,
      end: selectedSchedule.end,
      isPublished: selectedSchedule.isPublished,
    } as UpdateScheduleInput,
  });

  const handleDeleteEventDialog = useCallback(() => {
    const modal = showModal(DeleteItemDialog, {
      title: "スケジュールの削除",
      description: "本当に削除してもよろしいですか？",
      onConfirm: () => {
        dispatch({
          type: "DELETE_ENTITY_DATA",
          payload: {
            shopId: selectedSchedule.shopId,
            entityName: "Schedule",
            input: {
              shopId: selectedSchedule.shopId,
              id: selectedSchedule.id,
            } as DeleteScheduleInput,
          } as DeleteEntityModel,
        });
        modal.hide();
        onCancel();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  }, [dispatch, onCancel, selectedSchedule, showModal]);

  const onSubmit: SubmitHandler<UpdateScheduleInput> = (data) => {
    enqueueSnackbar("スケジュールの更新を開始しました", {
      variant: "info",
    });
    dispatch({
      type: "UPDATE_ENTITY_DATA",
      payload: {
        shopId: store.shop?.id,
        entityName: "Schedule",
        input: data,
      } as UpdateEntityModel,
    });

    onConfirm();
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" {...props}>
      <Box p={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>{description}</DialogContentText>
            {selectedSchedule ? (
              <TextField
                margin="dense"
                id="duration"
                sx={{ width: 100 }}
                label="所要時間"
                disabled
                variant="outlined"
                value={selectedSchedule.event?.duration}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">分</InputAdornment>
                  ),
                }}
              />
            ) : null}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <TimePicker
                  renderInput={(params) => <TextField {...params} />}
                  value={startTime}
                  label="開始時間"
                  onChange={(newValue) => {
                    setStartTime(newValue);
                    setValue("start", newValue?.toISOString());
                    trigger("start");
                    const end = new Date(
                      newValue?.getTime()! +
                        selectedSchedule.event?.duration! * 60000
                    );
                    setEndTime(end);
                    setValue("end", end.toISOString()!);
                    trigger("end");
                    //setEndTime(newValue);
                  }}
                  minTime={new Date(0, 0, 0, 9)}
                  maxTime={new Date(0, 0, 0, 21, 0)}
                  minutesStep={5}
                />
                <TimePicker
                  renderInput={(params) => <TextField {...params} />}
                  value={endTime}
                  disabled
                  label="終了時間"
                  onChange={(newValue) => {
                    setEndTime(newValue);
                    setValue("end", newValue?.toISOString());
                  }}
                  minTime={new Date(0, 0, 0, 10)}
                  maxTime={new Date(0, 0, 0, 22, 0)}
                  minutesStep={5}
                />
              </Stack>
            </LocalizationProvider>

            <Box>
              <FormGroup>
                <FormControlLabel
                  label="非公開/公開"
                  control={
                    <Switch
                      color="primary"
                      defaultChecked={selectedSchedule.isPublished}
                      onChange={(e) => {
                        setValue("isPublished", e.target.checked);
                      }}
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
            px={1}
            boxSizing="border-box"
          >
            <Box>
              <Button
                variant="contained"
                color="error"
                disableElevation
                onClick={handleDeleteEventDialog}
              >
                削除する
              </Button>
            </Box>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={onCancel}
              >
                閉じる
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSubmit(onSubmit)}
              >
                更新
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
