import { i18next } from "../translations/i18next";
import * as yup from "yup";

type SignUpInput = {
  name: string;
  address: string;
  email: string;
  tel: string;
};

export const SignUpSchema = (lang: "en" | "ja") => {
  i18next.changeLanguage(lang);
  const schema: yup.SchemaOf<SignUpInput> = yup.object({
    name: yup.string().required(i18next.t("name.required")!),
    address: yup.string().required(i18next.t("address.required")!),
    email: yup.string().email(i18next.t("email.email")!).required(i18next.t("email.required")!),
    tel: yup.string().required(i18next.t("tel.required")!),
  });

  return schema;
};
