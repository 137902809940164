import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { CustomerIndexPage } from "../pages/customer/CustomerIndexPage";
import { ProfilePage } from "../pages/config/ProfilePage";
import { ReservationIndexPage } from "../pages/reservation/ReservationIndexPage";
import { ScheduleReservationPage } from "../pages/ScheduleReservationPage";
import { SimpleAdmin } from "../theme/simple_admin/SimpleAdmin";
import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import { CreateEventPage } from "../pages/event/CreateEventPage";
import { EventIndexPage } from "../pages/event/EventIntexPage";
import { EditEventPage } from "../pages/event/EditEventPage";
import { ReservationPerDayPage } from "../pages/reservation/ReservationPerDay";
import { CustomerEditPage } from "../pages/customer/CustomerEditPage";
import { IndexPage } from "../pages/IndexPage";
import { LoginPage } from "../pages/LoginPage";

import { ScheduleIndexPage } from "../pages/schedule/ScheduleIndexPage";
import { SignupPage } from "../pages/SignupPage";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Navigate to="login" />} />
          <Route
            path={"/login"}
            element={<LoginPage/>}
          />
          <Route
            path={"/signup"}
            element={<SignupPage/>}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/u/:userId" element={<SimpleAdmin />}>
            <Route path="dashboard" element={<IndexPage />} />
            <Route path="config">
              <Route path="profile" element={<ProfilePage />} />
            </Route>
            <Route path="reservations" element={<ReservationIndexPage />} />
            <Route path="reservation">
              <Route path=":day" element={<ReservationPerDayPage />} />
            </Route>
            <Route path="schedules" element={<ScheduleIndexPage/>} />
            <Route path="schedule">
              <Route
                path=":scheduleId/reservations"
                element={<ScheduleReservationPage />}
              />
            </Route>
            <Route path="events" element={<EventIndexPage />} />
            <Route path="event/new" element={<CreateEventPage />} />
            <Route path="event/:eventId" element={<EditEventPage />} />
            <Route path="customers" element={<CustomerIndexPage />} />
            <Route path="customer">
              <Route path=":customerId" element={<CustomerEditPage />} />
            </Route>

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
