import { Box, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EventForm } from "../../components/Form/EventForm";
import { CreateEventItems } from "../../DAL/repositories/EventRepository";
import { StoreResource, StoreTransactionStatus, useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { CreateEntityModel } from "../../provider/reducer/RepositoryAsyncReducer";
import { ResourceOperation } from "../../util/graphqlEvents";

export const CreateEventPage = () => {
  const {store, dispatch} = useApplicationStore();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback((input: CreateEventItems)=>{
    enqueueSnackbar("イベントの作成を開始しました。", {
      variant: "info",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    dispatch({
      type: ResourceOperation.SET_TRANSACTION,
      payload: {
        resource: StoreResource.event,
        method: "create",
        status: StoreTransactionStatus.processing
      }
    })
    dispatch({
      type: "CREATE_ENTITY_DATA",
      payload: {
        shopId: store.shop?.id,
        entityName: "Event",
        input: input
      } as CreateEntityModel
    });
    navigate(`/u/${store.shop?.id}/events`);
  },[dispatch, enqueueSnackbar, navigate, store.shop?.id])
  
  useEffect(()=>{

  },[store.events])
  return (
    <Box maxWidth={800} margin="auto">
      <Box p={1}>
        <Typography variant="h2">新規イベント作成</Typography>
      </Box>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <EventForm onSubmitFn={onSubmit}/>
    </Box>
  );
};
