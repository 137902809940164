import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { DeleteDialogProps } from "../type";

export const DeleteItemDialog: React.FC<DeleteDialogProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  ...props
}) => {
  return (
    <Dialog {...props}>
      <Box p={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>{description}</DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            キャンセル
          </Button>
          <Button
            disableElevation
            onClick={(e) => {
              onConfirm();
            }}
            color="error"
          >
            削除する
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};