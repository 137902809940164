import { formatRange } from "@fullcalendar/react";
import { Box, Card, Chip, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Reservation } from "../../API";
import { ScheduleReservedCustomerTable } from "../Table/ScheduleReservedCustomerTable";

type Props = {
  reservation?: Reservation[][] | null;
};

export const GroupedReservationPanel = React.memo<Props>(({ reservation }) => {
  return (
    <Stack spacing={2}>
      {reservation?.length! > 0 ? (
        reservation?.map((group, index) => (
          <Paper key={`grouped-reservation-${index}`} variant="outlined">
            <Box py={2.5} px={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h3">
                  {group[0].schedule?.event?.name}
                </Typography>
                <Chip
                  size="small"
                  label={`開催期間:${formatRange(
                    group[0].schedule!.start,
                    group[0].schedule!.end,
                    {
                      hour: "numeric",
                      minute: "numeric",
                      locale: "jp",
                      separator: "-",
                    }
                  )}`}
                />
              </Stack>
              <Divider sx={{ my: 1 }} />
              <ScheduleReservedCustomerTable reservations={group} />
            </Box>
          </Paper>
        ))
      ) : (
        <Card variant="outlined" elevation={0}>
          <Box p={1}>
            <Typography variant="body1">予約はありません</Typography>
          </Box>
        </Card>
      )}
    </Stack>
  );
});
