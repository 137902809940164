import { Box, Typography, Divider, Skeleton, Paper } from "@mui/material";
import { useEffect } from "react";
import { CustomerDataGrid } from "../../components/Datagrid/CustomerDataGrid";
import { CustomerRepository } from "../../DAL/repositories/CustomerRepository";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { ResourceOperation } from "../../util/graphqlEvents";

export const CustomerIndexPage = () => {
  const { store, dispatch } = useApplicationStore();

  useEffect(() => {
    if (store.shop !== null && store.customers === null) {
      CustomerRepository(store.shop?.id)
        .fetch()
        .then((result) =>
          dispatch({ type: ResourceOperation.FETCH_CUSTOMER, payload: result })
        )
        .catch((err) => console.info(err));
    }
  }, [store.shop]);

  return (
    <Box>
      <Typography color="inherit" variant="h2">
        顧客一覧
      </Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Paper elevation={0}>
        {store.customers !== null ? (
          <Box mt={2}>
            <CustomerDataGrid
              customers={store.customers}
              shopId={store.shop?.id!}
            />
          </Box>
        ) : (
          <Box>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        )}
      </Paper>
    </Box>
  );
};
