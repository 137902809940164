import { Authenticator } from "@aws-amplify/ui-react";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CognitoUser } from "@aws-amplify/auth";

export const LoginPage: React.FC = () => {
  return (
    <Box mt={8}>
      <Authenticator
        hideSignUp={false}
        signUpAttributes={["name", "email", "phone_number", "address"]}
        formFields={{
          signUp: {
            phone_number:{
              labelHidden: true,
              placeholder: "電話番号",              
              required: true,
              dialCode: '+81'
            },
            address: {
              labelHidden: true,
              placeholder: "住所",
              isRequired: true,
            },
            "custom:token": {
              labelHidden: true,
              placeholder: "トークン",
              isRequired: true,
            },
          },
        }}
      />
      {/* 
      <Stack mt={1}>
        <Typography
          variant="subtitle2"
          textAlign="center"
          component={Link}
          to="/signup"
        >
          アカウントを作っていない方はこちら
        </Typography>
      </Stack>
      */}
    </Box>
  );
};
