import { formatDate } from "@fullcalendar/react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import React, { useCallback } from "react";
import {
  Reservation,
  ReservationStatus,
  UpdateReservationInput,
} from "../../API";
import { CancelReservationInput } from "../../DAL/repositories/ReservationRepository";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { UpdateEntityModel } from "../../provider/reducer/RepositoryAsyncReducer";
import { ReservationStatusChip } from "../Chip/ReservationStatusChip";
import { ComfirmDialog } from "../Dialogs/Common/ConfirmDialog";
import { ReservationListMenu } from "../Menu/ReservationListMenu";

type Props = {
  reservations: Reservation[];
};
export const ScheduleReservedCustomerTable = React.memo<Props>(
  ({ reservations }) => {
    const { showModal } = useModal();
    const { store, dispatch } = useApplicationStore();
    const handleCancelComfirmationDialog = useCallback(
      (reservation: Reservation) => {
        const modal = showModal(ComfirmDialog, {
          title: "予約のキャンセル",
          description: "本当にキャンセルしてもよろしいですか？",
          executeBtnTxt: "キャンセルする",
          onConfirm: () => {
            const updateInput: UpdateReservationInput = {
              shopId: reservation.shopId,
              id: reservation.id,
              scheduleId: reservation.scheduleId,
              status: ReservationStatus.Canceled,
            };
            dispatch({
              type: "UPDATE_ENTITY_DATA",
              payload: {
                shopId: reservation?.shopId,
                entityName: "Reservation",
                input: {
                  updateInput: updateInput,
                } as CancelReservationInput,
              } as UpdateEntityModel,
            });
            modal.hide();
          },
          onCancel: () => {
            modal.hide();
          },
        });
      },
      [dispatch, showModal, store.customers, store.shop?.id]
    );

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>顧客</TableCell>
              <TableCell>メールアドレス</TableCell>
              <TableCell>状態</TableCell>
              <TableCell width={32}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations.map((reservation, index) => (
              <TableRow key={`${reservation.id}-${index}`}>
                <TableCell>
                  {formatDate(reservation.createdAt, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    locale: "ja",
                  })}
                </TableCell>
                <TableCell>{reservation.customer?.name}</TableCell>
                <TableCell>{reservation.customer?.email}</TableCell>
                <TableCell>
                  <ReservationStatusChip status={reservation.status!}/>
                </TableCell>
                <TableCell width={32}>
                  <ReservationListMenu
                    reservation={reservation}
                    cancelHandler={handleCancelComfirmationDialog}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);
