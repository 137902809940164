import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { ComfirmDialogProps } from "../type";

export const ComfirmDialog: React.FC<ComfirmDialogProps> = ({
  title,
  description,
  executeBtnTxt,
  onCancel,
  onConfirm,
  ...props
}) => {
  return (
    <Dialog {...props}>
      <Box p={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>{description}</DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} variant="outlined" color="primary">
            閉じる
          </Button>
          <Button
            onClick={(e) => {
              onConfirm();
            }}
            variant="contained"
            color="primary"
            disableElevation
          >
            {executeBtnTxt}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};