import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useMatch, useParams } from "react-router-dom";
import { Customer, Schedule } from "../API";
import { CustomerDataGrid } from "../components/Datagrid/CustomerDataGrid";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";

export const ScheduleReservationPage = () => {
  const { store } = useApplicationStore();
  const [reservedSchedule, setReservedSchedule] = useState<Schedule>();
  const {shopId} = useParams();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const match = useMatch("/u/:userId/reservation/s/:scheduleId");
  useEffect(() => {
    if (store.schedules !== null) {
      const _schedule = store.schedules.find(
        (s) => s.id === match?.params.scheduleId
      );
      if (_schedule?.reservations?.items!.length! > 0) {
        setReservedSchedule(_schedule);
        const _customers = _schedule?.reservations?.items.map((item)=>item?.customer) as Customer[];
        setCustomers(_customers);
      }
    }
  }, []);
  return (
    <>
      <Box p={1}>
        {reservedSchedule ? (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Stack>
                <Typography color="inherit" variant="subtitle1">
                  {reservedSchedule?.start}
                </Typography>
                <Typography color="inherit" variant="h4" component="h1">
                  {reservedSchedule?.event?.name}の予約状況
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Skeleton animation="wave" />
        )}
      </Box>
      <Divider sx={{ mt: 1, mb: 2 }} />
      {reservedSchedule ? (
        <CustomerDataGrid customers={customers} shopId={shopId!}/>
      ) : null}
    </>
  );
};