/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type BatchAddScheduleInput = {
  shopId: string,
  data?: Array< ScheduleInput | null > | null,
};

export type ScheduleInput = {
  shopId: string,
  id?: string | null,
  eventId: string,
  start: string,
  end: string,
  isPublished: boolean,
  isExpired: boolean,
  capacity?: number | null,
  acceptableNumber?: number | null,
};

export type BatchAddSchedulesResult = {
  __typename: "BatchAddSchedulesResult",
  shopId: string,
  data?:  Array<Schedule | null > | null,
};

export type Schedule = {
  __typename: "Schedule",
  shopId: string,
  id: string,
  eventId: string,
  event?: Event | null,
  start: string,
  end: string,
  capacity?: number | null,
  acceptableNumber?: number | null,
  reservations?: ModelReservationConnection | null,
  isPublished: boolean,
  isExpired?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Event = {
  __typename: "Event",
  id: string,
  shopId: string,
  name: string,
  thumbnail?: string | null,
  description?: string | null,
  duration?: number | null,
  capacity?: number | null,
  canPublish: boolean,
  color?: string | null,
  order?: number | null,
  cancelTimeoutLimit?: number | null,
  reserveTimeoutLimit?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelReservationConnection = {
  __typename: "ModelReservationConnection",
  items:  Array<Reservation | null >,
  nextToken?: string | null,
};

export type Reservation = {
  __typename: "Reservation",
  shopId: string,
  id: string,
  status?: ReservationStatus | null,
  expiredAt?: string | null,
  isExpired?: boolean | null,
  scheduleId: string,
  schedule?: Schedule | null,
  transactionId: string,
  transaction?: ReservationTransaction | null,
  customerId: string,
  customer?: Customer | null,
  createdAt: string,
  updatedAt: string,
};

export enum ReservationStatus {
  Entry = "Entry",
  Success = "Success",
  Faild = "Faild",
  Canceled = "Canceled",
}


export type ReservationTransaction = {
  __typename: "ReservationTransaction",
  reservationId: string,
  id: string,
  customerId: string,
  details?:  Array<TransactionContent | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type TransactionContent = {
  __typename: "TransactionContent",
  id: string,
  process: ReservationProcess,
  status: TransactionStatus,
  message?: string | null,
  createdAt: string,
};

export enum ReservationProcess {
  Entry = "Entry",
  Payment = "Payment",
  CapacityConfirmation = "CapacityConfirmation",
}


export enum TransactionStatus {
  Processing = "Processing",
  Pending = "Pending",
  Success = "Success",
  Faild = "Faild",
}


export type Customer = {
  __typename: "Customer",
  shopId: string,
  id: string,
  name?: string | null,
  lineId?: string | null,
  email?: string | null,
  reservations?: ModelReservationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type CreatePreReservationInput = {
  shopId: string,
  id?: string | null,
  scheduleId: string,
  status?: ReservationStatus | null,
};

export type CustomerInput = {
  shopId: string,
  id?: string | null,
  name: string,
  lineId?: string | null,
  email: string,
  exists: boolean,
};

export type CreatePreReservationResult = {
  __typename: "CreatePreReservationResult",
  transaction?: ReservationTransaction | null,
  customer?: Customer | null,
};

export type CreateShopInput = {
  id?: string | null,
  name: string,
  address?: string | null,
  email?: string | null,
  tel?: string | null,
  dayoff?: Array< number | null > | null,
  holidays?: Array< string | null > | null,
};

export type ModelShopConditionInput = {
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  dayoff?: ModelIntInput | null,
  holidays?: ModelStringInput | null,
  and?: Array< ModelShopConditionInput | null > | null,
  or?: Array< ModelShopConditionInput | null > | null,
  not?: ModelShopConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Shop = {
  __typename: "Shop",
  id: string,
  name: string,
  address?: string | null,
  email?: string | null,
  tel?: string | null,
  dayoff?: Array< number | null > | null,
  holidays?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateShopInput = {
  id: string,
  name?: string | null,
  address?: string | null,
  email?: string | null,
  tel?: string | null,
  dayoff?: Array< number | null > | null,
  holidays?: Array< string | null > | null,
};

export type DeleteShopInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  shopId: string,
  name: string,
  thumbnail?: string | null,
  description?: string | null,
  duration?: number | null,
  capacity?: number | null,
  canPublish: boolean,
  color?: string | null,
  order?: number | null,
  cancelTimeoutLimit?: number | null,
  reserveTimeoutLimit?: number | null,
};

export type ModelEventConditionInput = {
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  capacity?: ModelIntInput | null,
  canPublish?: ModelBooleanInput | null,
  color?: ModelStringInput | null,
  order?: ModelIntInput | null,
  cancelTimeoutLimit?: ModelIntInput | null,
  reserveTimeoutLimit?: ModelIntInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateEventInput = {
  id: string,
  shopId?: string | null,
  name?: string | null,
  thumbnail?: string | null,
  description?: string | null,
  duration?: number | null,
  capacity?: number | null,
  canPublish?: boolean | null,
  color?: string | null,
  order?: number | null,
  cancelTimeoutLimit?: number | null,
  reserveTimeoutLimit?: number | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateScheduleInput = {
  shopId: string,
  id?: string | null,
  eventId: string,
  start: string,
  end: string,
  capacity?: number | null,
  acceptableNumber?: number | null,
  isPublished: boolean,
  isExpired?: boolean | null,
};

export type ModelScheduleConditionInput = {
  eventId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  capacity?: ModelIntInput | null,
  acceptableNumber?: ModelIntInput | null,
  isPublished?: ModelBooleanInput | null,
  isExpired?: ModelBooleanInput | null,
  and?: Array< ModelScheduleConditionInput | null > | null,
  or?: Array< ModelScheduleConditionInput | null > | null,
  not?: ModelScheduleConditionInput | null,
};

export type UpdateScheduleInput = {
  shopId: string,
  id: string,
  eventId?: string | null,
  start?: string | null,
  end?: string | null,
  capacity?: number | null,
  acceptableNumber?: number | null,
  isPublished?: boolean | null,
  isExpired?: boolean | null,
};

export type DeleteScheduleInput = {
  shopId: string,
  id: string,
};

export type CreateReservationInput = {
  shopId: string,
  id?: string | null,
  status?: ReservationStatus | null,
  expiredAt?: string | null,
  isExpired?: boolean | null,
  scheduleId: string,
  transactionId: string,
  customerId: string,
};

export type ModelReservationConditionInput = {
  status?: ModelReservationStatusInput | null,
  expiredAt?: ModelStringInput | null,
  isExpired?: ModelBooleanInput | null,
  scheduleId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelReservationConditionInput | null > | null,
  or?: Array< ModelReservationConditionInput | null > | null,
  not?: ModelReservationConditionInput | null,
};

export type ModelReservationStatusInput = {
  eq?: ReservationStatus | null,
  ne?: ReservationStatus | null,
};

export type UpdateReservationInput = {
  shopId: string,
  id: string,
  status?: ReservationStatus | null,
  expiredAt?: string | null,
  isExpired?: boolean | null,
  scheduleId?: string | null,
  transactionId?: string | null,
  customerId?: string | null,
};

export type DeleteReservationInput = {
  shopId: string,
  id: string,
};

export type CreateReservationTransactionInput = {
  reservationId: string,
  id?: string | null,
  customerId: string,
  details?: Array< TransactionContentInput | null > | null,
};

export type TransactionContentInput = {
  id?: string | null,
  process: ReservationProcess,
  status: TransactionStatus,
  message?: string | null,
  createdAt?: string | null,
};

export type ModelReservationTransactionConditionInput = {
  customerId?: ModelIDInput | null,
  and?: Array< ModelReservationTransactionConditionInput | null > | null,
  or?: Array< ModelReservationTransactionConditionInput | null > | null,
  not?: ModelReservationTransactionConditionInput | null,
};

export type UpdateReservationTransactionInput = {
  reservationId: string,
  id: string,
  customerId?: string | null,
  details?: Array< TransactionContentInput | null > | null,
};

export type DeleteReservationTransactionInput = {
  reservationId: string,
  id: string,
};

export type CreateCustomerInput = {
  shopId: string,
  id?: string | null,
  name?: string | null,
  lineId?: string | null,
  email?: string | null,
};

export type ModelCustomerConditionInput = {
  name?: ModelStringInput | null,
  lineId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerInput = {
  shopId: string,
  id: string,
  name?: string | null,
  lineId?: string | null,
  email?: string | null,
};

export type DeleteCustomerInput = {
  shopId: string,
  id: string,
};

export type GetAccountResult = {
  __typename: "GetAccountResult",
  shopId?: string | null,
  id?: string | null,
  name?: string | null,
  lineId?: string | null,
  email?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelShopFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  dayoff?: ModelIntInput | null,
  holidays?: ModelStringInput | null,
  and?: Array< ModelShopFilterInput | null > | null,
  or?: Array< ModelShopFilterInput | null > | null,
  not?: ModelShopFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelShopConnection = {
  __typename: "ModelShopConnection",
  items:  Array<Shop | null >,
  nextToken?: string | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  capacity?: ModelIntInput | null,
  canPublish?: ModelBooleanInput | null,
  color?: ModelStringInput | null,
  order?: ModelIntInput | null,
  cancelTimeoutLimit?: ModelIntInput | null,
  reserveTimeoutLimit?: ModelIntInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelScheduleFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  capacity?: ModelIntInput | null,
  acceptableNumber?: ModelIntInput | null,
  isPublished?: ModelBooleanInput | null,
  isExpired?: ModelBooleanInput | null,
  and?: Array< ModelScheduleFilterInput | null > | null,
  or?: Array< ModelScheduleFilterInput | null > | null,
  not?: ModelScheduleFilterInput | null,
};

export type ModelScheduleConnection = {
  __typename: "ModelScheduleConnection",
  items:  Array<Schedule | null >,
  nextToken?: string | null,
};

export type ModelReservationFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  status?: ModelReservationStatusInput | null,
  expiredAt?: ModelStringInput | null,
  isExpired?: ModelBooleanInput | null,
  scheduleId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelReservationFilterInput | null > | null,
  or?: Array< ModelReservationFilterInput | null > | null,
  not?: ModelReservationFilterInput | null,
};

export type ModelReservationTransactionFilterInput = {
  reservationId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelReservationTransactionFilterInput | null > | null,
  or?: Array< ModelReservationTransactionFilterInput | null > | null,
  not?: ModelReservationTransactionFilterInput | null,
};

export type ModelReservationTransactionConnection = {
  __typename: "ModelReservationTransactionConnection",
  items:  Array<ReservationTransaction | null >,
  nextToken?: string | null,
};

export type ModelCustomerFilterInput = {
  shopId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  lineId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
};

export type BatchAddSchedulesMutationVariables = {
  batchAddScheduleInput?: BatchAddScheduleInput | null,
};

export type BatchAddSchedulesMutation = {
  batchAddSchedules?:  {
    __typename: "BatchAddSchedulesResult",
    shopId: string,
    data?:  Array< {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type CreatePreReservationMutationVariables = {
  preReservationInput?: CreatePreReservationInput | null,
  customerInput?: CustomerInput | null,
};

export type CreatePreReservationMutation = {
  createPreReservation?:  {
    __typename: "CreatePreReservationResult",
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateShopMutationVariables = {
  input: CreateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type CreateShopMutation = {
  createShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    dayoff?: Array< number | null > | null,
    holidays?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateShopMutationVariables = {
  input: UpdateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type UpdateShopMutation = {
  updateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    dayoff?: Array< number | null > | null,
    holidays?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteShopMutationVariables = {
  input: DeleteShopInput,
  condition?: ModelShopConditionInput | null,
};

export type DeleteShopMutation = {
  deleteShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    dayoff?: Array< number | null > | null,
    holidays?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    capacity?: number | null,
    canPublish: boolean,
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    capacity?: number | null,
    canPublish: boolean,
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    capacity?: number | null,
    canPublish: boolean,
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateScheduleMutationVariables = {
  input: CreateScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type CreateScheduleMutation = {
  createSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    start: string,
    end: string,
    capacity?: number | null,
    acceptableNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isPublished: boolean,
    isExpired?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateScheduleMutationVariables = {
  input: UpdateScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type UpdateScheduleMutation = {
  updateSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    start: string,
    end: string,
    capacity?: number | null,
    acceptableNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isPublished: boolean,
    isExpired?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteScheduleMutationVariables = {
  input: DeleteScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type DeleteScheduleMutation = {
  deleteSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    start: string,
    end: string,
    capacity?: number | null,
    acceptableNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isPublished: boolean,
    isExpired?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReservationMutationVariables = {
  input: CreateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type CreateReservationMutation = {
  createReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReservationMutationVariables = {
  input: UpdateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type UpdateReservationMutation = {
  updateReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReservationMutationVariables = {
  input: DeleteReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type DeleteReservationMutation = {
  deleteReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReservationTransactionMutationVariables = {
  input: CreateReservationTransactionInput,
  condition?: ModelReservationTransactionConditionInput | null,
};

export type CreateReservationTransactionMutation = {
  createReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReservationTransactionMutationVariables = {
  input: UpdateReservationTransactionInput,
  condition?: ModelReservationTransactionConditionInput | null,
};

export type UpdateReservationTransactionMutation = {
  updateReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReservationTransactionMutationVariables = {
  input: DeleteReservationTransactionInput,
  condition?: ModelReservationTransactionConditionInput | null,
};

export type DeleteReservationTransactionMutation = {
  deleteReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAccountQueryVariables = {
  shopId?: string | null,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "GetAccountResult",
    shopId?: string | null,
    id?: string | null,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type GetShopQueryVariables = {
  id: string,
};

export type GetShopQuery = {
  getShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    dayoff?: Array< number | null > | null,
    holidays?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListShopsQueryVariables = {
  id?: string | null,
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListShopsQuery = {
  listShops?:  {
    __typename: "ModelShopConnection",
    items:  Array< {
      __typename: "Shop",
      id: string,
      name: string,
      address?: string | null,
      email?: string | null,
      tel?: string | null,
      dayoff?: Array< number | null > | null,
      holidays?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    capacity?: number | null,
    canPublish: boolean,
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventsQueryVariables = {
  id?: string | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScheduleQueryVariables = {
  shopId: string,
  id: string,
};

export type GetScheduleQuery = {
  getSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    start: string,
    end: string,
    capacity?: number | null,
    acceptableNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isPublished: boolean,
    isExpired?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSchedulesQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSchedulesQuery = {
  listSchedules?:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReservationQueryVariables = {
  shopId: string,
  id: string,
};

export type GetReservationQuery = {
  getReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReservationsQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReservationsQuery = {
  listReservations?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      shopId: string,
      id: string,
      status?: ReservationStatus | null,
      expiredAt?: string | null,
      isExpired?: boolean | null,
      scheduleId: string,
      schedule?:  {
        __typename: "Schedule",
        shopId: string,
        id: string,
        eventId: string,
        event?:  {
          __typename: "Event",
          id: string,
          shopId: string,
          name: string,
          thumbnail?: string | null,
          description?: string | null,
          duration?: number | null,
          capacity?: number | null,
          canPublish: boolean,
          color?: string | null,
          order?: number | null,
          cancelTimeoutLimit?: number | null,
          reserveTimeoutLimit?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        start: string,
        end: string,
        capacity?: number | null,
        acceptableNumber?: number | null,
        reservations?:  {
          __typename: "ModelReservationConnection",
          nextToken?: string | null,
        } | null,
        isPublished: boolean,
        isExpired?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      transactionId: string,
      transaction?:  {
        __typename: "ReservationTransaction",
        reservationId: string,
        id: string,
        customerId: string,
        details?:  Array< {
          __typename: "TransactionContent",
          id: string,
          process: ReservationProcess,
          status: TransactionStatus,
          message?: string | null,
          createdAt: string,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      customerId: string,
      customer?:  {
        __typename: "Customer",
        shopId: string,
        id: string,
        name?: string | null,
        lineId?: string | null,
        email?: string | null,
        reservations?:  {
          __typename: "ModelReservationConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReservationTransactionQueryVariables = {
  reservationId: string,
  id: string,
};

export type GetReservationTransactionQuery = {
  getReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReservationTransactionsQueryVariables = {
  reservationId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelReservationTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReservationTransactionsQuery = {
  listReservationTransactions?:  {
    __typename: "ModelReservationTransactionConnection",
    items:  Array< {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  shopId: string,
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomersQueryVariables = {
  shopId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomerByLineIdQueryVariables = {
  lineId: string,
  shopId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByLineIdQuery = {
  customerByLineId?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnBatchAddSchedulesSubscriptionVariables = {
  shopId: string,
};

export type OnBatchAddSchedulesSubscription = {
  onBatchAddSchedules?:  {
    __typename: "BatchAddSchedulesResult",
    shopId: string,
    data?:  Array< {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type OnUpdateReservationByCustomerIdSubscriptionVariables = {
  customerId: string,
};

export type OnUpdateReservationByCustomerIdSubscription = {
  onUpdateReservationByCustomerId?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateShopSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateShopSubscription = {
  onCreateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    dayoff?: Array< number | null > | null,
    holidays?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateShopSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateShopSubscription = {
  onUpdateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    dayoff?: Array< number | null > | null,
    holidays?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteShopSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteShopSubscription = {
  onDeleteShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    address?: string | null,
    email?: string | null,
    tel?: string | null,
    dayoff?: Array< number | null > | null,
    holidays?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  shopId?: string | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    capacity?: number | null,
    canPublish: boolean,
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  shopId?: string | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    capacity?: number | null,
    canPublish: boolean,
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  shopId?: string | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    shopId: string,
    name: string,
    thumbnail?: string | null,
    description?: string | null,
    duration?: number | null,
    capacity?: number | null,
    canPublish: boolean,
    color?: string | null,
    order?: number | null,
    cancelTimeoutLimit?: number | null,
    reserveTimeoutLimit?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateScheduleSubscriptionVariables = {
  shopId?: string | null,
};

export type OnCreateScheduleSubscription = {
  onCreateSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    start: string,
    end: string,
    capacity?: number | null,
    acceptableNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isPublished: boolean,
    isExpired?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateScheduleSubscriptionVariables = {
  shopId?: string | null,
};

export type OnUpdateScheduleSubscription = {
  onUpdateSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    start: string,
    end: string,
    capacity?: number | null,
    acceptableNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isPublished: boolean,
    isExpired?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteScheduleSubscriptionVariables = {
  shopId?: string | null,
};

export type OnDeleteScheduleSubscription = {
  onDeleteSchedule?:  {
    __typename: "Schedule",
    shopId: string,
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      shopId: string,
      name: string,
      thumbnail?: string | null,
      description?: string | null,
      duration?: number | null,
      capacity?: number | null,
      canPublish: boolean,
      color?: string | null,
      order?: number | null,
      cancelTimeoutLimit?: number | null,
      reserveTimeoutLimit?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    start: string,
    end: string,
    capacity?: number | null,
    acceptableNumber?: number | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isPublished: boolean,
    isExpired?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReservationSubscriptionVariables = {
  shopId?: string | null,
};

export type OnCreateReservationSubscription = {
  onCreateReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReservationSubscriptionVariables = {
  shopId?: string | null,
};

export type OnUpdateReservationSubscription = {
  onUpdateReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReservationSubscriptionVariables = {
  shopId?: string | null,
};

export type OnDeleteReservationSubscription = {
  onDeleteReservation?:  {
    __typename: "Reservation",
    shopId: string,
    id: string,
    status?: ReservationStatus | null,
    expiredAt?: string | null,
    isExpired?: boolean | null,
    scheduleId: string,
    schedule?:  {
      __typename: "Schedule",
      shopId: string,
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        shopId: string,
        name: string,
        thumbnail?: string | null,
        description?: string | null,
        duration?: number | null,
        capacity?: number | null,
        canPublish: boolean,
        color?: string | null,
        order?: number | null,
        cancelTimeoutLimit?: number | null,
        reserveTimeoutLimit?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      start: string,
      end: string,
      capacity?: number | null,
      acceptableNumber?: number | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      isPublished: boolean,
      isExpired?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionId: string,
    transaction?:  {
      __typename: "ReservationTransaction",
      reservationId: string,
      id: string,
      customerId: string,
      details?:  Array< {
        __typename: "TransactionContent",
        id: string,
        process: ReservationProcess,
        status: TransactionStatus,
        message?: string | null,
        createdAt: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      shopId: string,
      id: string,
      name?: string | null,
      lineId?: string | null,
      email?: string | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        items:  Array< {
          __typename: "Reservation",
          shopId: string,
          id: string,
          status?: ReservationStatus | null,
          expiredAt?: string | null,
          isExpired?: boolean | null,
          scheduleId: string,
          transactionId: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReservationTransactionSubscription = {
  onCreateReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReservationTransactionSubscription = {
  onUpdateReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReservationTransactionSubscription = {
  onDeleteReservationTransaction?:  {
    __typename: "ReservationTransaction",
    reservationId: string,
    id: string,
    customerId: string,
    details?:  Array< {
      __typename: "TransactionContent",
      id: string,
      process: ReservationProcess,
      status: TransactionStatus,
      message?: string | null,
      createdAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    shopId: string,
    id: string,
    name?: string | null,
    lineId?: string | null,
    email?: string | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        shopId: string,
        id: string,
        status?: ReservationStatus | null,
        expiredAt?: string | null,
        isExpired?: boolean | null,
        scheduleId: string,
        schedule?:  {
          __typename: "Schedule",
          shopId: string,
          id: string,
          eventId: string,
          start: string,
          end: string,
          capacity?: number | null,
          acceptableNumber?: number | null,
          isPublished: boolean,
          isExpired?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        transactionId: string,
        transaction?:  {
          __typename: "ReservationTransaction",
          reservationId: string,
          id: string,
          customerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        customerId: string,
        customer?:  {
          __typename: "Customer",
          shopId: string,
          id: string,
          name?: string | null,
          lineId?: string | null,
          email?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
