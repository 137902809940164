import { Chip } from "@mui/material";
import React from "react";
import { ReservationStatus } from "../../API";

type Props = {
  status: ReservationStatus;
};
export const ReservationStatusChip = React.memo<Props>(({ status }) => {
  return (
    <Chip
      variant="filled"
      size="small"
      label={
        status === ReservationStatus.Success
          ? "成功"
          : status === ReservationStatus.Canceled
          ? "キャンセル"
          : status === ReservationStatus.Faild
          ? "失敗"
          : "未確定"
      }
      color={
        status === ReservationStatus.Success
          ? "success"
          : status === ReservationStatus.Canceled
          ? "error"
          : "default"
      }
    />
  );
});
