import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import {
  CreateCustomerInput,
  CreateCustomerMutation,
  ListCustomersQuery,
  Customer,
  //CreateCustomerAccountInput,
  //CreateCustomerAccountMutation,
  UpdateCustomerInput,
  UpdateCustomerMutation,
} from "../../API";
import {
  createCustomer,
  //createCustomerAccount,
  updateCustomer,
} from "../../graphql/mutations";
import { _listCustomers } from "../fixedQueries";
import { listCustomers } from "../../graphql/queries";

export const CustomerRepository = (_shopId: string | undefined) => {
  const shopId: string | undefined = _shopId;

  const fetch = () => {
    const _filter = {
      shopId: {
        eq: shopId,
      },
    };
    return new Promise<Customer[]>(async (res, rej) => {
      if (shopId === undefined) rej();
      const result = (await API.graphql(
        graphqlOperation(listCustomers, { filter: _filter })
      )) as GraphQLResult<ListCustomersQuery>;

      if (result.data?.listCustomers?.items) {
        const Customers = result.data.listCustomers.items as Customer[];
        res(Customers);
      } else {
        rej(new Error("empty"));
      }
    });
  };

  const create = (formState: CreateCustomerInput) => {
    const newCustomer: CreateCustomerInput = { ...formState };
    return new Promise<Customer>(async (res) => {
      const result = (await API.graphql(
        graphqlOperation(createCustomer, { input: newCustomer })
      )) as GraphQLResult<CreateCustomerMutation>;
      const Customer = result.data?.createCustomer as Customer;
      res(Customer);
    });
  };

  const update = (formState: UpdateCustomerInput) => {
    return new Promise<Customer>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(updateCustomer, { input: formState })
      )) as GraphQLResult<UpdateCustomerMutation>;
      if(result.errors){
        rej(result.errors)
      }else{
        res(result.data?.updateCustomer as Customer)
      }
    });
  };

  return {
    fetch,
    create,
    update
  };
};
