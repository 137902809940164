import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormGroup,
  Stack,
  TextField,
  Skeleton,
  Typography,
  Radio,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

import { CreateEventInput, Event, UpdateEventInput } from "../../API";
import Grid from "@mui/material/Grid";
import { Image } from "@aws-amplify/ui-react";
import { getImageFromS3 } from "../../util/storage";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { LoadingButton } from "@mui/lab";
import {
  CreateEventItems,
  UpdateEventItems,
} from "../../DAL/repositories/EventRepository";

type EventFormProps = {
  selectedEvent?: Event;
  isLoading?: boolean;
  onSubmitFn: (input: any) => void;
};

const formSchema = yup.object({
  name: yup.string().required("イベント名の入力は必須です。"),
  description: yup.string().required("説明文の入力は必須です"),
  thumbnail: yup.string(),
  canPublish: yup.boolean().required(),
  capacity: yup
    .number()
    .typeError("半角数字を入力してください。")
    .positive("0以上の数値を入力してください")
    .integer("整数のみ入力してください。")
    .required("定員は必須です。"),
  duration: yup
    .number()
    .typeError("半角数字を入力してください。")
    .positive("0以上の数値を入力してください")
    .integer("整数のみ入力してください。")
    .required("イベントの所要時間の入力は必須です"),
  color: yup.string().required("表示色を選択してください。"),
  reserveTimeoutLimit: yup
    .number()
    .round("round")
    .typeError("半角数字を入力してください。")
    .positive("正の数字を入力してください。")
    .required("キャンセル可能時間を入力してください。"),
  cancelTimeoutLimit: yup
    .number()
    .round("round")
    .typeError("半角数字を入力してください。")
    .positive("正の数字を入力してください。")
    .required("キャンセル可能時間を入力してください。"),
  order: yup
    .number()
    .round("round")
    .typeError("半角数字を入力してください。")
    .positive("正の数字を入力してください。"),
});

const colorList: string[] = [
  "#ef5350",
  "#ec407a",
  "#ab47bc",
  "#5c6bc0",
  "#039be5",
  "#009688",
  "#43a047",
  "#ef6c00",
];

const convertProps = (event: Event) => {
  const { __typename, createdAt, updatedAt, ...eventInfo } = event;
  return eventInfo as UpdateEventInput;
};

export const EventForm = React.memo<EventFormProps>(
  ({ selectedEvent, onSubmitFn, isLoading }) => {
    const { store } = useApplicationStore();
    const [uploadImage, setUploadImage] = useState<File>();
    const [uploadImageSrc, setUploadImageSrc] = useState<string>();

    const initialValue = selectedEvent
      ? convertProps(selectedEvent)
      : ({
          shopId: store.shop?.id,
          name: "",
          canPublish: false,
          duration: 60,
          capacity: 1,
        } as CreateEventInput);

    const {
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
    } = useForm<CreateEventInput | UpdateEventInput>({
      resolver: yupResolver(formSchema),
      defaultValues: initialValue,
    });

    const currentColor = watch("color");

    const initializeSelectedValue = useCallback(
      (event: Event) => {
        if (event.thumbnail) {
          getImageFromS3(event.thumbnail)
            .then((result) => {
              setValue("thumbnail", event.thumbnail);
              setUploadImageSrc(result);
            })
            .catch((err) => {});
        }
        setValue("duration", event.duration);
        setValue(
          "cancelTimeoutLimit",
          (event.cancelTimeoutLimit || 3600000) / 3600000
        );
        setValue(
          "reserveTimeoutLimit",
          (event.reserveTimeoutLimit || 3600000) / 3600000
        );
      },
      [setValue]
    );

    useEffect(() => {
      if (selectedEvent) initializeSelectedValue(selectedEvent);
    }, [initializeSelectedValue, selectedEvent]);

    const fileReaderHandler = (event: ProgressEvent<FileReader>) => {
      setUploadImageSrc(event.target?.result as string);
    };

    const imageSelectedHandler = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (!event.target.files![0]) return;
      const fileData = new FileReader();
      fileData.onloadend = fileReaderHandler;
      setValue("thumbnail", image_path + event.target.files![0].name);
      setUploadImage(event.target.files![0]);
      fileData.readAsDataURL(event.target.files![0]);
    };

    const image_path = "u/" + store.shop?.id + "/images/events/";

    const colorProps = (item: string) => ({
      checked: currentColor === item,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
        setValue("color", event.target.value),
      value: item,
      name: "color-radio-button-demo",
      inputProps: { "aria-label": item },
    });

    const onSubmit: SubmitHandler<CreateEventInput | UpdateEventInput> = (
      data
    ) => {
      if (selectedEvent) {
        const input = {
          shopId: selectedEvent.shopId,
          thumbnailName: data.thumbnail,
          thumbnailData: uploadImage,
          formState: {
            ...data,
            cancelTimeoutLimit: data.cancelTimeoutLimit! * 3600000,
            reserveTimeoutLimit: data.reserveTimeoutLimit! * 3600000,
          },
        } as UpdateEventItems;
        onSubmitFn(input);
      } else {
        const input = {
          shopId: store.shop?.id,
          thumbnailName: data.thumbnail,
          thumbnailData: uploadImage,
          formState: {
            ...data,
            cancelTimeoutLimit: data.cancelTimeoutLimit! * 3600000,
            reserveTimeoutLimit: data.reserveTimeoutLimit! * 3600000,
          },
        } as CreateEventItems;
        onSubmitFn(input);
      }
    };

    return (
      <Stack spacing={4}>
        <TextField
          margin="dense"
          id="name"
          label="イベント名"
          fullWidth
          variant="outlined"
          {...register("name")}
          error={"name" in errors}
          helperText={errors.name?.message}
          placeholder="イベント名を入力してください"
        />
        <TextField
          id="outlined-multiline-static"
          label="説明文"
          multiline
          rows={5}
          {...register("description")}
          error={"description" in errors}
          helperText={errors.description?.message}
        />
        <TextField
          margin="dense"
          id="duration"
          sx={{ width: 200 }}
          label="所要時間"
          variant="outlined"
          {...register("duration")}
          error={"duration" in errors}
          helperText={errors.duration?.message}
          placeholder="所要時間を入力してください"
          InputProps={{
            endAdornment: <InputAdornment position="end">分</InputAdornment>,
          }}
        />
        <Box width={200}>
          <FormControl>
            <InputLabel htmlFor="outlined-event-capacity">定員</InputLabel>
            <OutlinedInput
              id="outlined-event-capacity"
              label="定員"
              type="number"
              defaultValue={10}
              {...register("capacity")}
              error={"capacity" in errors}
              endAdornment={<InputAdornment position="end">人</InputAdornment>}
            />
            <FormHelperText className="MuiFormHelperText-root Mui-error">
              {errors.capacity?.message}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box>
          <Typography mb={2}>イベント画像</Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {!uploadImageSrc ? (
                <Skeleton
                  variant="rectangular"
                  height={250}
                  animation={false}
                />
              ) : (
                <Image src={uploadImageSrc} alt="selected_image" width="100%" />
              )}
            </Grid>
            <Grid item xs={6}>
              <input
                type="file"
                accept="image/*"
                onChange={imageSelectedHandler}
                id="raised-button-file"
                style={{ display: "none" }}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" disableElevation>
                  画像を選択する。
                </Button>
              </label>
            </Grid>
          </Grid>
        </Box>        
        <FormGroup>
          <FormControl>
            <InputLabel htmlFor="event_reserveTimeoutLimit">
              予約受付可能時間
            </InputLabel>
            <OutlinedInput
              id="event_reserveTimeoutLimit"
              label="予約受付可能時間"
              type="number"
              defaultValue={12}
              {...register("reserveTimeoutLimit")}
              endAdornment={
                <InputAdornment position="start">
                  時間前まで予約可能
                </InputAdornment>
              }
            />
            <FormHelperText className="Mui-error">
              {errors.reserveTimeoutLimit?.message}
            </FormHelperText>
          </FormControl>
        </FormGroup>
        <FormGroup>
          <FormControl>
            <InputLabel htmlFor="event_cancelTimeoutLimit">
              キャンセル受付時間
            </InputLabel>
            <OutlinedInput
              id="event_cancelTimeoutLimit"
              label="キャンセル受付時間"
              type="number"
              defaultValue={24}
              {...register("cancelTimeoutLimit")}
              endAdornment={
                <InputAdornment position="start">
                  時間前までキャンセル可能
                </InputAdornment>
              }
            />
            <FormHelperText className="Mui-error">
              {errors.cancelTimeoutLimit?.message}
            </FormHelperText>
          </FormControl>
        </FormGroup>
        <FormGroup sx={{ maxWidth: "180px" }}>
          <FormControl>
            <InputLabel htmlFor="event_cancelTimeoutLimit">表示順</InputLabel>
            <OutlinedInput
              id="event_cancelTimeoutLimit"
              label="表示順"
              type="number"
              {...register("order")}
            />
            <FormHelperText className="Mui-error">
              {errors.order?.message}
            </FormHelperText>
          </FormControl>
        </FormGroup>
        <FormGroup>
          <Box flex={1} mt={2}>
            <InputLabel id="event-color-label">識別色</InputLabel>
            <Stack mt={1} direction="row" spacing={1}>
              {colorList.map((_c) => (
                <Radio
                  key={"event-color" + _c}
                  {...colorProps(_c)}
                  sx={{
                    "&.MuiRadio-root": {
                      color: _c,
                    },
                    "&.Mui-checked": {
                      color: _c,
                    },
                  }}
                />
              ))}
            </Stack>
            <FormHelperText className="Mui-error">
              {errors.color?.message}
            </FormHelperText>
          </Box>
        </FormGroup>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box>
            <LoadingButton
              variant="contained"
              loading={isLoading ?? false}
              onClick={handleSubmit(onSubmit)}
              color="primary"
            >
              {selectedEvent ? "保存する" : "作成する"}
            </LoadingButton>
          </Box>
        </Stack>
      </Stack>
    );
  }
);
