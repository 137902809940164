import { Reducer } from "react";
import { AsyncActionHandlers } from "use-reducer-async";
import {
  CreateCustomerInput,
  DeleteCustomerInput,
  DeleteEventInput,
  DeleteScheduleInput,
  UpdateCustomerInput,
  UpdateEventInput,
  UpdateReservationInput,
  UpdateScheduleInput,
  UpdateShopInput,
} from "../../API";
import { CustomerRepository } from "../../DAL/repositories/CustomerRepository";
import {
  CreateEventItems,
  DeleteEventItems,
  EventRepository,
  UpdateEventItems,
} from "../../DAL/repositories/EventRepository";
import {
  CancelReservationInput,
  PreReserveInput,
  ReservationRepository,
} from "../../DAL/repositories/ReservationRepository";
import {
  ScheduleRepository,
  StyledCreateScheduleInput,
} from "../../DAL/repositories/ScheduleRepository";
import { ShopRepository } from "../../DAL/repositories/ShopRepository";
import { ResourceOperation } from "../../util/graphqlEvents";
import { STORE } from "../ApplicationStoreProvider";
import { EntityCURDServiceProvider } from "../EntityCURDServiceProvider";
import { ACTIONTYPE } from "./StoreReducer";

type FetchDataInput = {
  shopId: string;
};

export type CreateEntityModel = {
  shopId: string;
  entityName: string;
  input:
    | CreateEventItems
    | StyledCreateScheduleInput[]
    | PreReserveInput
    | CreateCustomerInput;
};

export type UpdateEntityModel = {
  shopId: string;
  entityName: string;
  input:
    | UpdateShopInput
    | UpdateEventItems
    | UpdateEventInput
    | CancelReservationInput
    | UpdateScheduleInput
    | UpdateCustomerInput;
};

export type DeleteEntityModel = {
  shopId: string;
  entityName: string;
  input:
    | DeleteEventItems
    | DeleteEventInput
    | DeleteScheduleInput
    | DeleteCustomerInput;
};

export type AsyncAction =
  | {
      type: "FETCH_CORE_DATA";
      payload: FetchDataInput;
    }
  | {
      type: "FETCH_SHOP_DATA";
      payload: FetchDataInput;
    }
  | {
      type: "FETCH_CUSTOMER_DATA";
      payload: FetchDataInput;
    }
  | {
      type: "FETCH_RESERVATION_DATA";
      payload: FetchDataInput;
    }
  | {
      type: "CREATE_ENTITY_DATA";
      payload: CreateEntityModel;
    }
  | {
      type: "UPDATE_ENTITY_DATA";
      payload: UpdateEntityModel;
    }
  | {
      type: "DELETE_ENTITY_DATA";
      payload: DeleteEntityModel;
    };

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<STORE, ACTIONTYPE>,
  AsyncAction
> = {
  FETCH_CORE_DATA:
    ({ dispatch }) =>
    async (action) => {
      Promise.all([
        ShopRepository(action.payload.shopId).fetch(),
        EventRepository(action.payload.shopId).fetch(),
        CustomerRepository(action.payload.shopId).fetch(),
        ScheduleRepository(action.payload.shopId).fetch(),
        ReservationRepository(action.payload.shopId).fetch(),
      ])
        .then((results) => {
          dispatch({
            type: ResourceOperation.FETCH_SHOP,
            payload: results[0],
          });
          dispatch({
            type: ResourceOperation.FETCH_LESSON,
            payload: results[1],
          });
          dispatch({
            type: ResourceOperation.FETCH_CUSTOMER,
            payload: results[2],
          });
          dispatch({
            type: ResourceOperation.FETCH_SCHEDULE,
            payload: results[3],
          });
          dispatch({
            type: ResourceOperation.FETCH_RESERVATION,
            payload: results[4],
          });
        })
        .catch((err) => console.log(err));
    },
  FETCH_SHOP_DATA:
    ({ dispatch }) =>
    async (action) => {
      ShopRepository(action.payload.shopId)
        .fetch()
        .then((result) => {
          dispatch({
            type: ResourceOperation.FETCH_SHOP,
            payload: result,
          });
        })
        .catch((err) => console.log(err));
    },
  FETCH_CUSTOMER_DATA:
    ({ dispatch }) =>
    async (action) => {
      CustomerRepository(action.payload.shopId)
        .fetch()
        .then((result) => {
          dispatch({
            type: ResourceOperation.FETCH_CUSTOMER,
            payload: result,
          });
        })
        .catch((err) => console.log(err));
    },
  FETCH_RESERVATION_DATA:
    ({ dispatch }) =>
    async (action) => {
      Promise.all([
        ScheduleRepository(action.payload.shopId).fetch(),
        ReservationRepository(action.payload.shopId).fetch(),
        //CustomerRepository(store.user?.id).fetch(),
      ])
        .then((results) => {
          dispatch({
            type: ResourceOperation.FETCH_SCHEDULE,
            payload: results[0],
          });
          dispatch({
            type: ResourceOperation.FETCH_RESERVATION,
            payload: results[1],
          });
          //dispatch({type:"customers:fetch",payload:result[4]})
        })
        .catch((err) => console.log(err));
    },
  CREATE_ENTITY_DATA:
    ({ dispatch }) =>
    async (action) => {
      const serviceClient = EntityCURDServiceProvider();
      serviceClient.createEntity(dispatch, action.payload);
    },
  UPDATE_ENTITY_DATA:
    ({ dispatch }) =>
    async (action) => {
      const serviceClient = EntityCURDServiceProvider();
      serviceClient.updateEntity(dispatch, action.payload);
    },
  DELETE_ENTITY_DATA:
    ({ dispatch }) =>
    async (action) => {
      const serviceClient = EntityCURDServiceProvider();
      serviceClient.deleteEntitiy(dispatch, action.payload);
    },
};
