import {
  Box,
  Divider,
  Skeleton,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteEventInput, Event, Schedule } from "../../API";
import { EventForm } from "../../components/Form/EventForm";
import {
  UpdateEventItems,
} from "../../DAL/repositories/EventRepository";
import {
  StoreResource,
  StoreTransactionStatus,
  useApplicationStore,
} from "../../provider/ApplicationStoreProvider";
import {
  DeleteEntityModel,
  UpdateEntityModel,
} from "../../provider/reducer/RepositoryAsyncReducer";
import { ResourceOperation } from "../../util/graphqlEvents";
import DeleteIcon from "@mui/icons-material/Delete";
import { useModal } from "mui-modal-provider";
import { DeleteItemDialog } from "../../components/Dialogs/Common/DeleteItemDialog";
import { formatRange } from "@fullcalendar/react";
import { DeleteCautionDialog } from "../../components/Dialogs/Common/DeleteCautionDialog";

export const EditEventPage = () => {
  const { store, dispatch } = useApplicationStore();
  const [selectedEvent, setEvent] = useState<Event>();
  const { eventId } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const onSubmit = useCallback(
    (input: UpdateEventItems) => {
      setLoading(true);
      enqueueSnackbar("イベントの編集を開始しました。", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      dispatch({
        type: ResourceOperation.SET_TRANSACTION,
        payload: {
          resource: StoreResource.event,
          method: "update",
          status: StoreTransactionStatus.processing,
        },
      });
      dispatch({
        type: "UPDATE_ENTITY_DATA",
        payload: {
          shopId: store.shop?.id,
          entityName: "Event",
          input: input,
        } as UpdateEntityModel,
      });
    },
    [dispatch, enqueueSnackbar, store.shop?.id]
  );

  const handleDeleteComfirmationDialog = useCallback(
    (selectedEvent?: Event) => {
      const modal = showModal(DeleteItemDialog, {
        title: "イベント削除",
        description: "本当に削除してもよろしいですか？",
        onConfirm: () => {
          dispatch({
            type: "DELETE_ENTITY_DATA",
            payload: {
              shopId: selectedEvent?.shopId,
              entityName: "Event",
              input: {
                shopId: selectedEvent?.shopId,
                id: selectedEvent?.id,
              } as DeleteEventInput,
            } as DeleteEntityModel,
          });
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
      });
    },
    [dispatch, showModal]
  );
  const handleDeleteCautionDialog = useCallback(
    (containedSchedules: Schedule[]) => {
      const eventsText: string[] = containedSchedules.map((schedule) =>
        formatRange(schedule.start, schedule.end, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          separator: "-",
          locale: "ja",
        })
      );
      const modal = showModal(DeleteCautionDialog, {
        title: "このイベントは削除できません。",
        description:
          "このイベントは以下の日程でスケジュールに登録されているため、削除できません。\nこのイベントをスケジュールから削除してから操作してください。\n",
        containedTargetText: eventsText,
        onConfirm: () => {
          modal.hide();
        },
      });
    },
    [showModal]
  );

  const handleDeleteDialog = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      const eventRegisteredSchedules = store.schedules?.filter(
        (schedule) => schedule.eventId === selectedEvent?.id
      );
      if (eventRegisteredSchedules!.length > 0) {
        handleDeleteCautionDialog(eventRegisteredSchedules!);
      } else {
        handleDeleteComfirmationDialog(selectedEvent);
      }
    },
    [
      handleDeleteCautionDialog,
      handleDeleteComfirmationDialog,
      selectedEvent,
      store.schedules,
    ]
  );

  useEffect(() => {
    if (
      store.transaction?.resource === StoreResource.event &&
      store.transaction.method === "update" &&
      store.transaction.status === StoreTransactionStatus.success
    ) {
      setLoading(false);
      enqueueSnackbar("イベントの更新が完了しました。", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });

      dispatch({
        type: ResourceOperation.SET_TRANSACTION,
        payload: {
          resource: StoreResource.event,
          status: StoreTransactionStatus.idle,
        },
      });
    }

    if (store.events !== null) {
      setEvent(store.events.find((event) => event.id === eventId));
    }
  }, [
    store.events,
    store.transaction,
    enqueueSnackbar,
    dispatch,
    eventId,
  ]);
  return (
    <Box maxWidth={800} margin="auto">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={1}
      >
        <Typography variant="h2">イベントの編集</Typography>
        <Box>
          <IconButton size="small" onClick={handleDeleteDialog}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      {selectedEvent ? (
        <EventForm
          selectedEvent={selectedEvent}
          onSubmitFn={onSubmit}
          isLoading={isLoading}
        />
      ) : (
        <Box>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      )}
    </Box>
  );
};
