import { Box, Button, Card, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignupFormProp } from "../../util/AppAuth";
import React from "react";
import { SignUpSchema } from "../../util/schema/SignUpFormSchema";

type Props = {
  onSubmitFn?: (input: SignupFormProp) => void;
};

export const SignupForm = React.memo<Props>(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormProp>({
    resolver: yupResolver(SignUpSchema("ja")),
    defaultValues: {
      name: "",
      address: "",
      email: "",
      tel: "",
    },
  });
  const submitHandler  = () =>{

  }
  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        width: "100%",
        maxWidth: 440,
      }}
    >
      <Box pt={3}>
        <Typography variant="body1" textAlign="center">
          お客様情報を入力してサインアップをしてください。
        </Typography>
      </Box>
      <Stack spacing={3} py={2} px={3}>
        <TextField
          margin="dense"
          size="small"
          id="name"
          label="会社名"
          required
          fullWidth
          variant="outlined"
          {...register("name")}
          error={"name" in errors}
          helperText={errors.name?.message}
          placeholder="株式会社○○"
        />
        <TextField
          margin="dense"
          size="small"
          id="address"
          required
          label="住所"
          fullWidth
          variant="outlined"
          {...register("address")}
          error={"address" in errors}
          helperText={errors.address?.message}
          placeholder="住所"
        />
        <TextField
          margin="dense"
          size="small"
          id="tel"
          label="電話番号"
          required
          fullWidth
          variant="outlined"
          {...register("tel")}
          error={"name" in errors}
          helperText={errors.tel?.message}
          placeholder="03-1234-5607"
          InputProps={{
            startAdornment:<InputAdornment position="start">+81</InputAdornment>
          }}
        />
        <TextField
          margin="dense"
          size="small"
          id="email"
          label="メールアドレス"
          required
          fullWidth
          variant="outlined"
          {...register("email")}
          error={"email" in errors}
          helperText={errors.email?.message}
          placeholder="example@test.com"
        />
        <Stack justifyContent="center" alignItems="center">
          <Box>
            <Button variant="contained" disableElevation size="medium" onClick={handleSubmit(submitHandler)}>
              <Typography variant="body1">サインアップ</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
});
