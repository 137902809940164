export const _listSchedules = /* GraphQL */ `
  query ListSchedules(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchedules(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        eventId
        capacity
        acceptableNumber
        event {
          id
          shopId
          name
          thumbnail
          description
          canPublish
          capacity
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        isPublished
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            customer {
              id
              lineId
              name
              email
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const _getSchedule = /* GraphQL */ `
  query GetSchedule($shopId: ID!, $id: ID!) {
    getSchedule(shopId: $shopId, id: $id) {
      shopId
      id
      eventId
      capacity
      acceptableNumber
      event {
        id
        shopId
        name
        thumbnail
        description
        canPublish
        capacity
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      isPublished
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          transactionId
          customerId
          customer {
            id
            lineId
            name
            email
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const _listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        shopId
        name
        description
        thumbnail
        duration
        canPublish
        capacity
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const _listCustomers = /* GraphQL */ `
  query ListCustomers(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const _listReservations = /* GraphQL */ `
  query ListReservations(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReservations(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        scheduleId
        schedule {
          shopId
          id
          eventId
          event {
            id
            name
          }
          start
          end
          isPublished
          isExpired
          createdAt
          updatedAt
        }
        customerId
        customer {
          shopId
          id
          name
          email
          createdAt
          updatedAt
        }
        status
        transactionId
        transaction {
          reservationId
          id
          customerId
          createdAt
          updatedAt
        }
        expiredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const _batchDeleteEventMasterTickets = /* GraphQL */ `
  mutation BatchDeleteEventMasterTickets($ids: [ID]) {
    batchDeleteEventMasterTickets(ids: $ids) {
      id
    }
  }
`;
