import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { DeleteCautionDialogProps } from "../type";


export const DeleteCautionDialog: React.FC<DeleteCautionDialogProps> = ({
  title,
  description,
  containedTargetText,
  onConfirm,
  ...props
}) => (
  <Dialog {...props}>
    <Box p={2}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <DialogContentText>{description}</DialogContentText>
        </Stack>
        <Stack mt={2} spacing={1}>
          {containedTargetText.map((text,idx) => (
            <DialogContentText key={"DeleteCautionText"+idx}>{text}</DialogContentText>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          onClick={(e) => {
            onConfirm();
          }}
          color="primary"
        >
          閉じる
        </Button>
      </DialogActions>
    </Box>
  </Dialog>
);
