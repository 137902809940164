import { Grid, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  matchRoutes,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  usePageNavigation,
  PageNavigationEvent,
} from "./PageNavigation/provider";
import { AppRoute, AppRoutes, NavigationRoutes } from "./PageNavigation/route";
import { SideNavi } from "./PageNavigation/SideNavi/SideNavi";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers";
import styled from "@emotion/styled";

const StyledDatePicker = styled(StaticDatePicker)({
  "& .MuiPickersCalendarHeader-labelContainer": {
    cursor: "default",
    pointerEvents: "none",
    button: {
      display: "none",
    },
  },
});

export const MainContent = React.memo(() => {
  const { pageNavigationState, pageNavigationDispatcher } = usePageNavigation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [day, setDay] = useState<Date | null>(new Date());
  const [showDayPanel, setShowDayPanel] = useState<boolean>(false);
  const [showDashboard, setShowDashboard] = useState<boolean>(true);
  const location = useLocation();
  const routes = matchRoutes(AppRoutes, location);
  const currentRoute =
    routes?.length! > 0
      ? (routes![0].route as AppRoute)
      : ({ path: "/" } as AppRoute);
  /*
  const defaultRoute =
    routes?.length! > 0 ? routes![0].route.path?.split("/")[3] : "/"; */

  useEffect(() => {
    if (pageNavigationState.currentRoute !== currentRoute.path) {
      pageNavigationDispatcher({
        type: PageNavigationEvent.onClickedHeaderNavi,
        payload: {
          childrenNavigationRoute: NavigationRoutes(userId!).children?.find(
            (item) => item.route === currentRoute.path
          ),
          currentRoute: currentRoute.path,
          parentRoute: currentRoute.parentPath,
        },
      });
    }
    setShowDayPanel(
      pageNavigationState.currentRoute === "/u/:userId/reservation/:day"
    );
    setShowDashboard(
      pageNavigationState.currentRoute === "/u/:userId/dashboard"
    );
  }, [pageNavigationState.currentRoute]);
  return (
    <Box>
      <Grid container py={4}>
        <Grid item xs={0} md={showDayPanel||showDashboard ? "auto" : 2.5}>
          {showDayPanel ? (
            <Box width={320}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  views={["month", "day"]}
                  disablePast
                  value={day}
                  onChange={(newValue) => {
                    setDay(newValue as Date);
                    navigate(
                      `/u/${userId}/reservation/${
                        (newValue as Date)?.toISOString().split("T")[0]
                      }`
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          ) : !showDashboard ? (
            <SideNavi
              navigationRoute={pageNavigationState.childrenNavigationRoute}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          md={showDayPanel ? "auto": showDashboard? 12 : 9.5}
          sx={
            showDayPanel
              ? { width: "calc(100% - 350px)!important", margin: "0 0 0 auto" }
              : null
          }
        >
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
});
