import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import {
  CreatePreReservationInput,
  CreatePreReservationMutation,
  CreatePreReservationResult,
  //CreatePreReservationInput,
  //CreatePreReservationMutation,
  CreateReservationInput,
  CreateReservationMutation,
  CustomerInput,
  DeleteReservationInput,
  DeleteReservationMutation,
  ListReservationsQuery,
  ModelEventFilterInput,
  Reservation,
  ReservationStatus,
  UpdateReservationInput,
  UpdateReservationMutation,
} from "../../API";
import {
  createPreReservation,
  //createPreReservation,
  createReservation,
  deleteReservation,
  updateReservation,
} from "../../graphql/mutations";
import { _listReservations } from "../fixedQueries";
export type CancelReservationInput = {
  updateInput: UpdateReservationInput;
};
export type PreReserveInput = {
  createPreReservationInput: CreatePreReservationInput
  customerInput: CustomerInput
};
export const ReservationRepository = (shopId: string | undefined) => {
  const fetch = () => {
    const listFilter: ModelEventFilterInput = {
      isExpired: {
        eq: false,
      },
    } as ModelEventFilterInput;
    return new Promise<Reservation[]>(async (res, rej) => {
      if (shopId === undefined) rej();
      const result = (await API.graphql(
        //graphqlOperation(listReservation)
        graphqlOperation(_listReservations, {
          shopId: shopId,
          filter: listFilter,
        })
      )) as GraphQLResult<ListReservationsQuery>;

      if (result.data?.listReservations?.items) {
        const Reservations = result.data.listReservations
          .items as Reservation[];
        res(Reservations);
      } else {
        rej(new Error("empty"));
      }
    });
  };

  const create = (formState: CreateReservationInput) => {
    const newReservation: CreateReservationInput = { ...formState };
    return new Promise<Reservation>(async (res) => {
      const result = (await API.graphql(
        graphqlOperation(createReservation, { input: newReservation })
      )) as GraphQLResult<CreateReservationMutation>;

      const Reservation = result.data?.createReservation as Reservation;
      res(Reservation);
    });
  };

  const update = (input: UpdateReservationInput) => {
    return new Promise<void>(async (res, rej) => {
      try {
        await API.graphql(
          graphqlOperation(updateReservation, { input: input })
        );
        res();
      } catch (err) {
        rej(new Error("update error"));
      }
    });
  };

  const cancel = (input: CancelReservationInput) => {
    return new Promise<Reservation>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(updateReservation, {
          input: input.updateInput,
        })
      )) as GraphQLResult<UpdateReservationMutation>;

      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.updateReservation as Reservation);
      }
    });
  };

  const remove = (input: DeleteReservationInput) => {
    return new Promise<Reservation>(async (res, rej) => {
      const result = API.graphql(
        graphqlOperation(deleteReservation, { input: input })
      ) as GraphQLResult<DeleteReservationMutation>;
      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.deleteReservation as Reservation);
      }
    });
  };

  const preReserve = (input: PreReserveInput) => {
    return new Promise<CreatePreReservationResult>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(createPreReservation, {
          preReservationInput: input.createPreReservationInput,
          customerInput: input.customerInput
        })
      )) as GraphQLResult<CreatePreReservationMutation>;
      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.createPreReservation!);
      }
    });
  };
  
  return {
    fetch,
    cancel,
    create,
    update,
    remove,
    preReserve,
  };
};
