import { formatDate } from "@fullcalendar/react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  timelineContentClasses,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Reservation, Schedule } from "../../API";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { usePageNavigation } from "../../theme/simple_admin/PageNavigation/provider";
import { GroupedReservationPanel } from "../Panel/GroupedReservationPanel";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

export const Dashboard = React.memo(() => {
  const { store, dispatch } = useApplicationStore();
  const today = new Date().toLocaleString().split(" ")[0];

  const [groupedReservations, setGroupedReservations] =
    useState<Reservation[][]>();
  const [selectedDateSchedules, setSelectedDataSchedules] =
    useState<Schedule[]>();

  useEffect(() => {
    if (store.shop !== null) {
      if (store.reservations === null) {
        dispatch({
          type: "FETCH_RESERVATION_DATA",
          payload: { shopId: store.shop.id },
        });
      } else {
        const reservations = store.reservations
          ?.filter((reservation) => {
            return (
              new Date(reservation.schedule!.start)
                .toLocaleString()
                .split(" ")[0] === today
            );
          })
          .sort((a, b) => (a.schedule!.start < b.schedule!.start ? -1 : 1)); //sort by schedule start time

        const scheduleIds = new Set<string>(
          reservations.map((r) => r.scheduleId)
        );
        setGroupedReservations(
          Array.from(scheduleIds.values()).map(
            (scheduleId) =>
              reservations
                .filter((r) => r.scheduleId === scheduleId)
                .sort((a, b) => (a.id < b.id ? -1 : 1)) //sort by reservation ulid
          )
        );
        setSelectedDataSchedules(
          store.schedules
            ?.filter(
              (schedule) =>
                new Date(schedule.start).toLocaleString().split(" ")[0] ===
                today
            )
            .sort((a, b) => (a.start < b.start ? -1 : 1)) //sort by schedule start time
        );
      }
    }
  }, [store.shop, store.reservations, dispatch]);
  return (
    <Grid container sx={{ width: "100%" }} spacing={5}>
      <Grid item xs={12} md={3.5}>
        <Box>
          <Typography variant="h2" fontWeight={600} mb={2}>
            本日のスケジュール
          </Typography>
          <Paper elevation={0}>
            <Box p={1}>
              {selectedDateSchedules?.length! > 0 ? (
                <Timeline
                  sx={{
                    m: 0,
                    p: "6px",
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                    [`& .${timelineContentClasses.root}`]: {
                      p: "6px 0 6px 16px",
                    },
                  }}
                >
                  {selectedDateSchedules?.map((schedule, index) => (
                    <TimelineItem key={schedule.id}>
                      <TimelineOppositeContent color="textSecondary">
                        <Typography variant="subtitle1">
                          {formatDate(schedule.start, {
                            hour: "2-digit",
                            minute: "numeric",
                          })}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot>
                          <SelfImprovementIcon fontSize="small" />
                        </TimelineDot>
                        {index !== selectedDateSchedules.length - 1 ? (
                          <TimelineConnector />
                        ) : null}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="subtitle1">
                          {schedule.event?.name}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              ) : (
                <Typography variant="body2">スケジュールはありません。</Typography>
              )}
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12} md>
        <Box>
          <Typography variant="h2" fontWeight={600}>
            本日の予約
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <GroupedReservationPanel reservation={groupedReservations} />
        </Box>
      </Grid>
    </Grid>
  );
});
