import { formatDate } from "@fullcalendar/react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Event} from "../../API";

type EventDataGrid = {
  events: Event[]
  shopId: string
}

export const EventDataGrid =  React.memo<EventDataGrid>(({events, shopId}) => {
  const navigate= useNavigate();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "イベント名",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "作成日",
      type: "date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return formatDate(new Date(Date.parse(params.value)), { locale: "ja" });
      },
      disableColumnMenu: true,
    }
  ];

  const createGirdRows = (events: Event[]) => {
    return events.map((event) => ({
      id: event.id,
      name: event.name,
      createdAt: event.createdAt,
    }));
  };

  return (
    <DataGrid
      columns={columns}
      rows={createGirdRows(events)}
      autoHeight
      density="compact"
      onRowClick={(e) => navigate(`/u/${shopId}/event/${e.row.id}`)}
      sx={{
        "& .MuiDataGrid-cell": {
          ":focus": {
            outlineColor: "transparent",
          },
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
          ":hover": {
            color: "primary.main",
          },
        },
        "& .MuiDataGrid-columnHeader":{
          ":last-child":{
            ".MuiDataGrid-iconSeparator":{
              visibility:"hidden"
            }
          }
        }
      }}
    />
  );
});
