import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventInput,
  formatDate,
} from "@fullcalendar/react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { Schedule } from "../../API";
import { ScheduleCalender } from "../../components/calender";
import { generateScheduleEvents } from "../../components/calender/utils";
import { CreateScheduleDialog } from "../../components/Dialogs/Schedule/CreateScheduleDialog";
import { EditScheduleDialog } from "../../components/Dialogs/Schedule/EditScheduleDialog";
import {
  StoreResource,
  StoreTransactionStatus,
  useApplicationStore,
} from "../../provider/ApplicationStoreProvider";
import { ResourceOperation } from "../../util/graphqlEvents";

export const ScheduleIndexPage: React.FC = () => {
  const [eventList, setEventList] = useState<EventInput[]>([]);
  const { store, dispatch } = useApplicationStore();
  const { enqueueSnackbar } = useSnackbar();
  const calendarRef = useRef<FullCalendar>(null!);
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const { showModal } = useModal();

  const handleEvents = useCallback((events: EventApi[]) => {
    setCurrentEvents(events);
  }, []);
  const handleEventClick = useCallback(
    (clickInfo: EventClickArg) => {
      const selectedSchedule = store.schedules?.find(
        (schedule) => schedule.id === clickInfo.event.id
      );
      if (Date.now() >= clickInfo.event?.start!.getTime()) return;
      const modal = showModal(EditScheduleDialog, {
        title: `${selectedSchedule?.event?.name}の編集`,
        description: "時間の変更または公開情報を編集してください。",
        selectedSchedule: selectedSchedule!,
        onConfirm: () => {
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
      });
    },
    [showModal, store.schedules]
  );
  const renderEventContent = useCallback(
    (eventContent: EventContentArg) => (
      <Box
        lineHeight={1.2}
        p={0.5}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "block ruby",
        }}
      >
        <Typography
          variant="caption"
          fontSize={13}
          fontWeight="bold"
          mr={0.5}
          lineHeight={1.2}
        >
          {eventContent.timeText}
        </Typography>
        <Typography
          variant="caption"
          fontSize={13}
          whiteSpace="normal"
          lineHeight={1.2}
        >
          {eventContent.event.title}
        </Typography>
      </Box>
    ),
    []
  );
  const selectDateHandler = useCallback(
    (selectInfo: DateSelectArg) => {
      if (Date.now() >= selectInfo.start.getTime()) return;
      const modal = showModal(CreateScheduleDialog, {
        title:
          formatDate(selectInfo.startStr, {
            month: "long",
            year: "numeric",
            day: "numeric",
            locale: "ja",
          }) + "のスケジュール追加",
        description: "開催するイベント情報を入力してください",
        selectedDate: selectInfo,
        onConfirm: () => {
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
      });
    },
    [showModal]
  );

  useEffect(() => {
    if (
      store.schedules !== null &&
      store.transaction?.resource === StoreResource.schedule
    ) {
      switch (store.transaction?.status) {
        case StoreTransactionStatus.idle:
          setEventList(generateScheduleEvents(store.schedules));
          break;
        case StoreTransactionStatus.success:
          const calendarApi = calendarRef.current?.getApi();
          switch (store.transaction.method) {
            case "create":
              const createdSchedules = store.transaction.data as Schedule[];
              createdSchedules.forEach((createdSchedule) => {
                calendarApi?.addEvent({
                  id: createdSchedule.id,
                  title: createdSchedule.event?.name,
                  start: createdSchedule.start,
                  end: createdSchedule.end,
                  display: "block",
                  backgroundColor: createdSchedule.isPublished
                    ? createdSchedule.event?.color!
                    : "#78909c",
                  borderColor: createdSchedule.isPublished
                    ? createdSchedule.event?.color!
                    : "#78909c",
                });
              });
              enqueueSnackbar("スケジュールの登録が完了しました。", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
              });
              break;
            case "update":
              const selectedSchedule = store.transaction.data as Schedule;
              const currentEvent = calendarApi.getEventById(
                selectedSchedule.id
              );
              currentEvent?.setStart(selectedSchedule.start)
              currentEvent?.setEnd(selectedSchedule.end)
       
              enqueueSnackbar("スケジュールの更新が完了しました。", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
              });
              break;
            case "delete":
              const deletedSchedule = store.transaction.data as Schedule;
              const deleteEvent = calendarApi.getEventById(
                deletedSchedule.id
              );
              deleteEvent?.remove()
              enqueueSnackbar("スケジュールの削除が完了しました。", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
              });
              break;
            default:
              break;
          }
          setEventList(generateScheduleEvents(store.schedules));
          dispatch({
            type: ResourceOperation.SET_TRANSACTION,
            payload: {
              resource: StoreResource.schedule,
              status: StoreTransactionStatus.idle,
            },
          });
          break;
        default:
          setEventList(generateScheduleEvents(store.schedules));
          dispatch({
            type: ResourceOperation.SET_TRANSACTION,
            payload: {
              resource: StoreResource.schedule,
              status: StoreTransactionStatus.idle,
            },
          });
          break;
      }
    }
  }, [dispatch, enqueueSnackbar, store.schedules, store.transaction]);

  return (
    <Box>
      <Typography variant="h2" fontWeight={600}>
        スケジュール
      </Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      {store.schedules !== null ? (
        <ScheduleCalender
          dateSelectHandler={handleEvents}
          eventClickHandler={handleEventClick}
          areaClickHandler={selectDateHandler}
          renderContent={renderEventContent}
          schedules={generateScheduleEvents(store.schedules)}
          calenderRef={calendarRef}
        />
      ) : (
        <Box>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      )}
    </Box>
  );
};
