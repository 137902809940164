import styled from "@emotion/styled";
import { Typography} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { SideNaviStack } from "./SideNaviStack";

type SideNaviItemProps = {
  label: string;
  path: string;
  isActive: boolean;
  children?: React.ReactNode;
};

const SideNaviLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
});
export const SideNaviItem = React.memo<SideNaviItemProps>(
  ({ label, path, isActive, children }) => {

    return (
      <SideNaviStack spacing={2} active={isActive}>
        <SideNaviLink to={path}>
          <Typography variant="body2" fontWeight={isActive ? 600 : 400}>
            {label}
          </Typography>
        </SideNaviLink>
      </SideNaviStack>
    );
  }
);
