/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($shopId: String) {
    getAccount(shopId: $shopId) {
      shopId
      id
      name
      lineId
      email
      createdAt
      updatedAt
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      address
      email
      tel
      dayoff
      holidays
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $id: ID
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShops(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        address
        email
        tel
        dayoff
        holidays
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      shopId
      name
      thumbnail
      description
      duration
      capacity
      canPublish
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($shopId: ID!, $id: ID!) {
    getSchedule(shopId: $shopId, id: $id) {
      shopId
      id
      eventId
      event {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      capacity
      acceptableNumber
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      isExpired
      createdAt
      updatedAt
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchedules(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($shopId: ID!, $id: ID!) {
    getReservation(shopId: $shopId, id: $id) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReservations(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        status
        expiredAt
        isExpired
        scheduleId
        schedule {
          shopId
          id
          eventId
          event {
            id
            shopId
            name
            thumbnail
            description
            duration
            capacity
            canPublish
            color
            order
            cancelTimeoutLimit
            reserveTimeoutLimit
            createdAt
            updatedAt
          }
          start
          end
          capacity
          acceptableNumber
          reservations {
            nextToken
          }
          isPublished
          isExpired
          createdAt
          updatedAt
        }
        transactionId
        transaction {
          reservationId
          id
          customerId
          details {
            id
            process
            status
            message
            createdAt
          }
          createdAt
          updatedAt
        }
        customerId
        customer {
          shopId
          id
          name
          lineId
          email
          reservations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReservationTransaction = /* GraphQL */ `
  query GetReservationTransaction($reservationId: ID!, $id: ID!) {
    getReservationTransaction(reservationId: $reservationId, id: $id) {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReservationTransactions = /* GraphQL */ `
  query ListReservationTransactions(
    $reservationId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelReservationTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReservationTransactions(
      reservationId: $reservationId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($shopId: ID!, $id: ID!) {
    getCustomer(shopId: $shopId, id: $id) {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $shopId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      shopId: $shopId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerByLineId = /* GraphQL */ `
  query CustomerByLineId(
    $lineId: ID!
    $shopId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByLineId(
      lineId: $lineId
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
