import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { Navigate, Outlet, RouteProps } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import {
  Backdrop,
  Card,
  CircularProgress, 
  Stack,
  Typography,
} from "@mui/material";

export const ProtectedRoute: React.FC<RouteProps> = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.route]);
  const { store, dispatch } = useApplicationStore();
  Amplify.configure({
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  });
  useEffect(() => {
    if (authStatus === "authenticated") {
      if (store.shop === null) {
        dispatch({
          type: "FETCH_CORE_DATA",
          payload: { shopId: user.attributes?.sub! },
        });
      }
    }
  }, [authStatus]);

  return authStatus === "authenticated" ? (
    <Outlet />
  ) : authStatus === "configuring" ? (
    <Backdrop open={true}>
      <Card elevation={0}>
        <Stack
          spacing={2}
          pt={2}
          px={2}
          pb={1}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="body2">認証中</Typography>
        </Stack>
      </Card>
    </Backdrop>
  ) : (
    <Navigate to="/login" />
  );
};
