import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  Skeleton,
  Stack,
  Paper,
} from "@mui/material";
import { useEffect } from "react";
import { ResourceOperation } from "../../util/graphqlEvents";
import { API, graphqlOperation } from "aws-amplify";
import { StoreResource, StoreTransactionStatus, useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { Link } from "react-router-dom";
import { EventRepository } from "../../DAL/repositories/EventRepository";
import { EventDataGrid } from "../../components/Datagrid/EventDataGrid";
import { useSnackbar } from "notistack";

export const EventIndexPage = () => {
  const { store, dispatch } = useApplicationStore();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (
      store.transaction?.resource === StoreResource.event &&
      store.transaction.method === "create" && 
      store.transaction.status === StoreTransactionStatus.success
    ) {
  
      enqueueSnackbar("イベントの登録が完了しました。", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      dispatch({
        type: ResourceOperation.SET_TRANSACTION,
        payload:{
          resource: StoreResource.ticket,
          status: StoreTransactionStatus.idle
        }
      });
    }

    if (store.shop !== null) {

      if (store.events === null) {
        EventRepository(store.shop?.id)
          .fetch()
          .then((result) => {
            dispatch({ type: ResourceOperation.FETCH_LESSON, payload: result });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [store.shop]);

  return (
    <Box>
      <Stack
        p={1}
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography variant="h2">イベント一覧</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          component={Link}
          to={`/u/${store.shop?.id}/event/new`}
        >
          新規追加
        </Button>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Paper elevation={0}>
        {store.events !== null ? (
          <EventDataGrid events={store.events} shopId={store.shop?.id!} />
        ) : (
          <Box>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        )}
      </Paper>
    </Box>
  );
};
