import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  Stack,
  TextField,
  Switch,
  Typography,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  MenuItem,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { formatDate } from "@fullcalendar/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Event} from "../../../API";
import { StyledCreateScheduleInput } from "../../../DAL/repositories/ScheduleRepository";
import { useApplicationStore } from "../../../provider/ApplicationStoreProvider";
import { CreateEntityModel } from "../../../provider/reducer/RepositoryAsyncReducer";
import { CreateScheduleBySelectedDateProps } from "../type";
import { monotonicFactory } from "ulid";

const ulid = monotonicFactory();

enum AddWeekType {
  EVERY = 0,
  OTHER = 1,
}

const formSchema = yup.object({
  shopId: yup.string().required(),
  eventId: yup.string().required("イベントの選択は必須です。"),
  start: yup.string().required("イベント開始時間を設定してください。"),
  end: yup.string().required("イベント終了時間の選択は必須です。"),
  isPublished: yup.boolean(),
  acceptableNumber: yup.number(),
});

export const CreateScheduleDialog: React.FC<
  CreateScheduleBySelectedDateProps
> = ({ title, description, selectedDate, onCancel, onConfirm, ...props }) => {
  const { store, dispatch } = useApplicationStore();
  const [selectedEventId, setSelectedEventId] = useState<string>("");
  const [selectableEvent, setSelectableEvent] = useState<Event[]>();
  const [eventDuration, setEventDuration] = useState<number>();
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [canAutoCreating, setCanAutoCreating] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [addOption, setAddOption] = useState<AddWeekType>(AddWeekType.EVERY);
  const [autoAddNum, setAutoAddNum] = useState<number>(0);
  const nowTime = new Date().getTime();

  const initialFormState = {
    id: ulid(nowTime),
    shopId: store.shop?.id,
    eventId: "",
    start: "",
    end: "",
    isPublished: false,
    isExpired: false,
    capacity: 1,
    acceptableNumber: 1,
  } as StyledCreateScheduleInput;

  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<StyledCreateScheduleInput>({
    resolver: yupResolver(formSchema),
    defaultValues: initialFormState,
  });

  const onSubmit: SubmitHandler<StyledCreateScheduleInput> = (data) => {
    enqueueSnackbar("スケジュールの登録を開始しました。", {
      variant: "info",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    if (canAutoCreating) {
      const _forms =
        addOption === AddWeekType.EVERY
          ? [...Array(autoAddNum)].map((_, idx) => {
              const _start = new Date(
                startTime!.getFullYear(),
                startTime!.getMonth(),
                startTime!.getDate() + 7 * (idx + 1),
                startTime!.getHours(),
                startTime!.getMinutes()
              );
              const _end = new Date(
                endTime!.getFullYear(),
                endTime!.getMonth(),
                endTime!.getDate() + 7 * (idx + 1),
                endTime!.getHours(),
                endTime!.getMinutes()
              );
              return {
                id: ulid(nowTime),
                shopId: data.shopId,
                eventId: data.eventId,
                start: new Date(_start.getTime()).toISOString(),
                end: new Date(_end.getTime()).toISOString(),
                capacity: data.capacity,
                acceptableNumber: data.capacity,
                isPublished: data.isPublished,
                isExpired: false,
                eventName: data.eventName,
                duration: eventDuration,
              };
            })
          : [...Array(autoAddNum)].map((_, idx) => {
              const _start = new Date(
                startTime!.getFullYear(),
                startTime!.getMonth(),
                startTime!.getDate() + 14 * (idx + 1),
                startTime!.getHours(),
                startTime!.getMinutes()
              );
              const _end = new Date(
                endTime!.getFullYear(),
                endTime!.getMonth(),
                endTime!.getDate() + 14 * (idx + 1),
                endTime!.getHours(),
                endTime!.getMinutes()
              );
              return {
                id: ulid(nowTime),
                shopId: data.shopId,
                eventId: data.eventId,
                start: new Date(_start.getTime()).toISOString(),
                end: new Date(_end.getTime()).toISOString(),
                capacity: data.capacity,
                acceptableNumber: data.capacity,
                isPublished: data.isPublished,
                isExpired: false,
                eventName: data.eventName,
                duration: eventDuration,
              };
            });
      //  const _first = data as ScheduleInput;
      dispatch({
        type: "CREATE_ENTITY_DATA",
        payload: {
          shopId: store.shop?.id,
          entityName: "Schedule",
          input: [data, ..._forms] as StyledCreateScheduleInput[],
        } as CreateEntityModel,
      });
    } else {
      dispatch({
        type: "CREATE_ENTITY_DATA",
        payload: {
          shopId: store.shop?.id,
          entityName: "Schedule",
          input: [
            {
              ...data,
              isExpired: false,
              eventName: data.eventName,
            },
          ] as StyledCreateScheduleInput[],
        } as CreateEntityModel,
      });
    }
    onConfirm();
  };

  const selectEventHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedEventId(event.target.value);
      setValue("eventId", event.target.value);
      trigger("eventId");
      const selectedEvent = store.events?.find(
        (ev) => ev.id === event.target.value
      );
      setEventDuration(selectedEvent?.duration!);
      setValue("eventName", selectedEvent?.name!);

      if (selectedEvent?.capacity) {
        setValue("capacity", selectedEvent.capacity);
        setValue("acceptableNumber", selectedEvent.capacity);
      }
    },
    [setValue, store.events, trigger]
  );

  const setStartTimeHandler = useCallback(
    (newValue: Date | null) => {
      const start = new Date(
        selectedDate.start.getFullYear(),
        selectedDate.start.getMonth(),
        selectedDate.start.getDate(),
        newValue?.getHours(),
        newValue?.getMinutes()
      );
      setStartTime(start);
      setValue("start", start.toISOString()!);
      trigger("start").then((value) => {
        if (value) {
          const end = new Date(start.getTime()! + eventDuration! * 60000);
          setEndTime(end);
          setValue("end", end.toISOString()!);
          trigger("end");
        }
      });
    },
    [eventDuration, setValue, trigger]
  );

  useEffect(() => {
    if (store.events !== null) {
      setSelectableEvent(store.events);
    }
  }, [store.events]);

  return (
    <Dialog fullWidth={true} maxWidth="sm" {...props}>
      <Box p={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>{description}</DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="schedule-event-type-label">
                開催イベント
              </InputLabel>
              <Select
                labelId="schedule-event-type-label"
                id="schedule-event-type"
                label="開催イベント"
                value={selectedEventId}
                onChange={selectEventHandler}
                error={"eventId" in errors}
              >
                {selectableEvent?.map((event) => (
                  <MenuItem key={event.id} value={event.id}>
                    {event.name}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText className="Mui-error">
                {errors.eventId?.message}
              </FormHelperText>
            </FormControl>
            {eventDuration ? (
              <TextField
                margin="dense"
                id="duration"
                sx={{ width: 100 }}
                label="所要時間"
                disabled
                variant="outlined"
                value={eventDuration}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">分</InputAdornment>
                  ),
                }}
              />
            ) : null}
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl>
                      <TimePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={startTime}
                        disabled={selectedEventId ? false : true}
                        label="開始時間"
                        onChange={setStartTimeHandler}
                        minTime={new Date(0, 0, 0, 9)}
                        maxTime={new Date(0, 0, 0, 21, 0)}
                        minutesStep={5}
                      />
                      <FormHelperText className="Mui-error">
                        {errors.start?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <TimePicker
                        renderInput={(params) => <TextField {...params} />}
                        value={endTime}
                        label="終了時間"
                        disabled
                        onChange={(newValue) => {
                          setEndTime(newValue);
                          setValue("end", newValue?.toISOString()!);
                          trigger("end");
                        }}
                        minTime={new Date(0, 0, 0, 9, 30)}
                        maxTime={new Date(0, 0, 0, 22, 0)}
                        minutesStep={5}
                      />
                      <FormHelperText className="Mui-error">
                        {errors.end?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
            <Stack spacing={2}>
              <FormGroup>
                <FormControlLabel
                  label="まとめて追加"
                  control={
                    <Switch
                      color="primary"
                      onChange={(e) => setCanAutoCreating(e.target.checked)}
                    />
                  }
                />
              </FormGroup>
              {canAutoCreating ? (
                <>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={6} pr={0.5}>
                      <FormControl fullWidth>
                        <InputLabel id="event-type-label">
                          毎週/隔週
                        </InputLabel>
                        <Select
                          labelId="event-type-label"
                          id="event-type"
                          label="毎週/隔週"
                          displayEmpty={false}
                          defaultValue={AddWeekType.EVERY}
                          value={addOption}
                          onChange={(e) => setAddOption(+e.target.value)}
                        >
                          <MenuItem value={AddWeekType.EVERY}>毎週</MenuItem>
                          <MenuItem value={AddWeekType.OTHER}>隔週</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} pl={0.5}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-add-option-num">
                          回数
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-add-option-num"
                          label="回数"
                          type="number"
                          value={autoAddNum}
                          onChange={(e) => {
                            if (+e.target.value < 0) return;
                            const num = +e.target.value;
                            setAutoAddNum(num);
                          }}
                          endAdornment={
                            <InputAdornment position="start">
                              回繰り返す
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {autoAddNum > 0 ? (
                      <Stack direction="row">
                        <Typography variant="body1" color="grey.700">
                          {formatDate(selectedDate.startStr, {
                            month: "long",
                            year: "numeric",
                            day: "numeric",
                            locale: "ja",
                          })}
                          から
                        </Typography>
                        <Typography variant="body1" color="grey.700">
                          {formatDate(
                            new Date(
                              selectedDate.start.getFullYear(),
                              selectedDate.start.getMonth(),
                              addOption === AddWeekType.EVERY
                                ? selectedDate.start.getDate() + 7 * autoAddNum
                                : selectedDate.start.getDate() + 14 * autoAddNum
                            ).toISOString(),
                            {
                              month: "long",
                              year: "numeric",
                              day: "numeric",
                              locale: "ja",
                            }
                          )}
                          までの
                        </Typography>
                        <Typography variant="body1" color="grey.700">
                          {addOption === AddWeekType.EVERY ? "毎週" : "隔週"}
                        </Typography>
                      </Stack>
                    ) : null}
                  </Grid>
                </>
              ) : null}
            </Stack>
            <Box>
              <FormGroup>
                <FormControlLabel
                  label="非公開/公開"
                  control={
                    <Switch
                      color="primary"
                      onChange={(e) => {
                        setValue("isPublished", e.target.checked);
                      }}
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="primary">
            キャンセル
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleSubmit(onSubmit)}
            color="primary"
          >
            作成
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
