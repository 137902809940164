import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Box,
  Skeleton,
} from "@mui/material";
import {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/react";
import { ReservationRepository } from "../../DAL/repositories/ReservationRepository";
import { ResourceOperation } from "../../util/graphqlEvents";
import { generateReservationEvents } from "../../components/calender/utils";
import { ReservationCalender } from "../../components/calender";
import DocumentMeta from "react-document-meta";
import { ScheduleRepository } from "../../DAL/repositories/ScheduleRepository";
import { useNavigate } from "react-router-dom";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";

export const ReservationIndexPage = () => {
  const { store, dispatch } = useApplicationStore();
  const meta = {
    title: "予約ページ",
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (store.shop !== null) {
      if (store.reservations === null) {
        Promise.all([
          ReservationRepository(store.shop?.id).fetch(),
          ScheduleRepository(store.shop?.id).fetch(),
        ])
          .then((results) => {
            dispatch({
              type: ResourceOperation.FETCH_RESERVATION,
              payload: results[0],
            });
            dispatch({
              type: ResourceOperation.FETCH_SCHEDULE,
              payload: results[1],
            });
          })
          .catch((err) => console.log(err));
      } else {
        console.log(store.reservations);
        //ReservationRepository(store.shop?.id).test().then(r=>console.log(r))
      }
    }
  }, []);

  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);

  const handleEvents = useCallback((events: EventApi[]) => {
    setCurrentEvents(events);
  }, []);

  const handleEventClick = useCallback(
    (clickInfo: EventClickArg) => {
      const selectedReservation = store.reservations?.find(
        (reservation) => reservation.id === clickInfo.event.id
      );
      const selectedSchedule = store.schedules?.find(
        (schedule) => schedule.id === selectedReservation?.scheduleId
      );
      //navigate(`/u/${store.shop?.id}/reservation/s/${selectedSchedule?.id}`);
    },
    [store.reservations, store.schedules]
  );

  const renderEventContent = (eventContent: EventContentArg) => (
    <Box
      lineHeight={1.2}
      p={0.5}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block ruby",
      }}
    >
      <Typography
        variant="caption"
        fontSize={13}
        fontWeight="bold"
        mr={0.5}
        lineHeight={1.2}
      >
        {eventContent.timeText}
      </Typography>
      <Typography
        variant="caption"
        fontSize={13}
        whiteSpace="normal"
        lineHeight={1.2}
      >
        {eventContent.event.title}
      </Typography>
    </Box>
  );

  const handleOpenConfirmationDialog = useCallback((arg: DateSelectArg) => {
    if (Date.now() >= arg.start.getTime()) return;
    navigate(`/u/${store.shop?.id}/reservation/${arg.startStr}`);
  }, []);

  return (
    <DocumentMeta {...meta}>
      <Box>
        <Typography variant="h2" fontWeight={600}>
          予約一覧
        </Typography>
        <Divider sx={{ mt: 1, mb: 2 }} />
        {store.reservations !== null ? (
          <ReservationCalender
            dateSelectHandler={handleEvents}
            eventClickHandler={handleEventClick}
            areaClickHandler={handleOpenConfirmationDialog}
            renderContent={renderEventContent}
            reservations={generateReservationEvents(store.reservations)}
          />
        ) : (
          <Box>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        )}
      </Box>
    </DocumentMeta>
  );
};
